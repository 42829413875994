/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 9c90f423 (Thu Aug 29 18:21:31 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  PaymentStartRequestBodyForEnrollments,
  PaymentStartRequestBodyForEnrollmentsFromJSON,
  PaymentStartRequestBodyForEnrollmentsToJSON,
} from './'

/**
 * Provides the type of payment, and the applicable data to apply this payment towards. Type can be `Licensing` or `Enrollment`. If `Licensing`, the `programKey` property must be populated. `Enrollments` will be ignored. If `Enrollment`, `enrollments` property must be populated and have at least one enrollment. `programKey` will be ignored.
 * @export
 * @interface PaymentStartRequestBodyFor
 */
export interface PaymentStartRequestBodyFor {
  /**
   *
   * @type {string}
   * @memberof PaymentStartRequestBodyFor
   */
  type: PaymentStartRequestBodyForTypeEnum
  /**
   *
   * @type {number}
   * @memberof PaymentStartRequestBodyFor
   */
  programKey?: number
  /**
   *
   * @type {Array<PaymentStartRequestBodyForEnrollments>}
   * @memberof PaymentStartRequestBodyFor
   */
  enrollments?: Array<PaymentStartRequestBodyForEnrollments>
}

/**
 * @export
 * @enum {string}
 */
export enum PaymentStartRequestBodyForTypeEnum {
  Licensing = 'Licensing',
  Enrollment = 'Enrollment',
}

export function PaymentStartRequestBodyForFromJSON(
  json: any
): PaymentStartRequestBodyFor {
  return PaymentStartRequestBodyForFromJSONTyped(json, false)
}

export function PaymentStartRequestBodyForFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PaymentStartRequestBodyFor {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    programKey: !exists(json, 'programKey') ? undefined : json['programKey'],
    enrollments: !exists(json, 'enrollments')
      ? undefined
      : (json['enrollments'] as Array<any>).map(
          PaymentStartRequestBodyForEnrollmentsFromJSON
        ),
  }
}

export function PaymentStartRequestBodyForToJSON(
  value?: PaymentStartRequestBodyFor | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    programKey: value.programKey,
    enrollments:
      value.enrollments === undefined
        ? undefined
        : (value.enrollments as Array<any>).map(
            PaymentStartRequestBodyForEnrollmentsToJSON
          ),
  }
}
