/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 9c90f423 (Thu Aug 29 18:21:31 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface PublicContact
 */
export interface PublicContact {
  /**
   *
   * @type {string}
   * @memberof PublicContact
   */
  firstName: string
  /**
   *
   * @type {string}
   * @memberof PublicContact
   */
  lastName: string
  /**
   *
   * @type {string}
   * @memberof PublicContact
   */
  imageUrl?: string
  /**
   *
   * @type {string}
   * @memberof PublicContact
   */
  bio?: string
  /**
   *
   * @type {string}
   * @memberof PublicContact
   */
  userKey?: string
  /**
   *
   * @type {string}
   * @memberof PublicContact
   */
  hubspotContactVid?: string
}

export function PublicContactFromJSON(json: any): PublicContact {
  return PublicContactFromJSONTyped(json, false)
}

export function PublicContactFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PublicContact {
  if (json === undefined || json === null) {
    return json
  }
  return {
    firstName: json['firstName'],
    lastName: json['lastName'],
    imageUrl: !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
    bio: !exists(json, 'bio') ? undefined : json['bio'],
    userKey: !exists(json, 'userKey') ? undefined : json['userKey'],
    hubspotContactVid: !exists(json, 'hubspotContactVid')
      ? undefined
      : json['hubspotContactVid'],
  }
}

export function PublicContactToJSON(value?: PublicContact | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    firstName: value.firstName,
    lastName: value.lastName,
    imageUrl: value.imageUrl,
    bio: value.bio,
    userKey: value.userKey,
    hubspotContactVid: value.hubspotContactVid,
  }
}
