/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 9c90f423 (Thu Aug 29 18:21:31 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { InvoiceInfo, InvoiceInfoFromJSON, InvoiceInfoToJSON } from './'

/**
 * Object that encapsulates all billing information for one director or tutor.
 * @export
 * @interface BillingInfo
 */
export interface BillingInfo {
  /**
   * The unique identifier of the director or tutor billing the family.
   * @type {number}
   * @memberof BillingInfo
   */
  billingUserKey: number
  /**
   * The full name of the director or tutor billing the family.
   * @type {string}
   * @memberof BillingInfo
   */
  billingUserFullName: string
  /**
   * Flag to designate if the tuition payments and invoices are for a tutor or a director.
   * @type {boolean}
   * @memberof BillingInfo
   */
  isTutorBilling: boolean
  /**
   * List of invoices sent from a director or tutor.
   * @type {Array<InvoiceInfo>}
   * @memberof BillingInfo
   */
  invoices: Array<InvoiceInfo>
}

export function BillingInfoFromJSON(json: any): BillingInfo {
  return BillingInfoFromJSONTyped(json, false)
}

export function BillingInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BillingInfo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    billingUserKey: json['billingUserKey'],
    billingUserFullName: json['billingUserFullName'],
    isTutorBilling: json['isTutorBilling'],
    invoices: (json['invoices'] as Array<any>).map(InvoiceInfoFromJSON),
  }
}

export function BillingInfoToJSON(value?: BillingInfo | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    billingUserKey: value.billingUserKey,
    billingUserFullName: value.billingUserFullName,
    isTutorBilling: value.isTutorBilling,
    invoices: (value.invoices as Array<any>).map(InvoiceInfoToJSON),
  }
}
