/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 9c90f423 (Thu Aug 29 18:21:31 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import { Program1, Program1FromJSON, Program1ToJSON } from './'

/**
 *
 * @export
 * @interface Family
 */
export interface Family {
  /**
   * The last name of the family.
   * @type {string}
   * @memberof Family
   */
  familyLastName: string
  /**
   * Name of the primary contact for the family.
   * @type {string}
   * @memberof Family
   */
  primaryContactName: string
  /**
   * Unique id of the primary contact for the family.
   * @type {number}
   * @memberof Family
   */
  primaryContactId: number
  /**
   * Email of the primary contact of the family.
   * @type {string}
   * @memberof Family
   */
  email?: string
  /**
   * Phone number of the primary contact of the family.
   * @type {string}
   * @memberof Family
   */
  phoneNumber?: string
  /**
   * The unique identifier of the community whose programs the family has students enrolled in.
   * @type {number}
   * @memberof Family
   */
  communityKey: number
  /**
   * The name of the community whose programs the family has students enrolled in.
   * @type {string}
   * @memberof Family
   */
  communityName: string
  /**
   * Programs that members of the family are enrolled in.
   * @type {Array<Program1>}
   * @memberof Family
   */
  programs: Array<Program1>
}

export function FamilyFromJSON(json: any): Family {
  return FamilyFromJSONTyped(json, false)
}

export function FamilyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Family {
  if (json === undefined || json === null) {
    return json
  }
  return {
    familyLastName: json['familyLastName'],
    primaryContactName: json['primaryContactName'],
    primaryContactId: json['primaryContactId'],
    email: !exists(json, 'email') ? undefined : json['email'],
    phoneNumber: !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
    communityKey: json['communityKey'],
    communityName: json['communityName'],
    programs: (json['programs'] as Array<any>).map(Program1FromJSON),
  }
}

export function FamilyToJSON(value?: Family | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    familyLastName: value.familyLastName,
    primaryContactName: value.primaryContactName,
    primaryContactId: value.primaryContactId,
    email: value.email,
    phoneNumber: value.phoneNumber,
    communityKey: value.communityKey,
    communityName: value.communityName,
    programs: (value.programs as Array<any>).map(Program1ToJSON),
  }
}
