import { useTheme } from '@mui/material/styles'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams, Outlet, useNavigate } from 'react-router'
import {
  extractedErrorObject,
  families,
  programs as programApi,
  invoicesApi,
  tuitionPaymentsApi,
  users,
} from '../../api/swagger'
import { buildAvatarUrl } from '../../api/user'
import {
  AcademicYearPrograms,
  BillingInfo,
  CashAmount,
  Program,
  Student,
  TuitionPaymentInfo,
  TuitionPayments,
  TuitionPaymentsInvoiceOriginatorKeyEnum,
  UserCommunity,
  UserProfile,
} from '../../swagger'
import { dateWithTimeToDashString } from '../../utils/dateUtility'
import { standardSort } from '../../utils/standardSort'
import { sumAmounts } from '../Card/InvoiceCard'
import ProfileCard, { ProfileCardVariant } from '../Card/ProfileCard'
import { CanAccess } from '../Elements/Access'
import Header, { HeaderVariant } from '../Elements/Header'
import PartialPageLoadingSpinner from '../Elements/PartialPageLoadingSpinner'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import InvoicePaymentModal, {
  InvoicePaymentModalVariant,
  PaymentModalState,
  TuitionEnrollmentOption,
  TuitionPaymentActionType,
} from '../Modals/InvoicePaymentModal'
import ProgramRenderer from '../Programs/ProgramRenderer'
import { useAuth } from '../Routes/AuthProvider'
import ChildrenSummaryCardForFamilies from './ChildrenSummaryCardForFamilies'
import EmptyFamilyProfile from './EmptyFamilyProfile'
import TuitionBillingTable, { TuitionBillingRow } from './TuitionBillingTable'
import { Page } from '../Elements/PageMargins'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { useSnackbarOnNavigation } from '../../hooks/useSnackbarOnNavigation'
import { styled } from '@mui/system'
import { Box } from '@mui/material'
import TitleContext from '../../TitleContext'
import { ElevatedCardVariants } from '../Card/ElevatedCard'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { LoadingContext } from '../Context/LoadingContext'
import useLoadingContext from '../../hooks/useLoadingContext'
import { useMountEffect } from '../../hooks/useMountEffect'
import { useAccountContext } from '../Context/AccountContext'
import { InvoiceDirectorMenu } from './InvoiceDirectorMenu'
import { useCalculateTotalAmount } from '../../hooks/useCalculateTotalAmount'
import { PaymentInfo } from '../../swagger/models/PaymentInfo'

const SectionPadding = styled('section')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(4, 0),
  },
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(4, 0),
  },
}))

interface TuitionInvoiceQueryParams {
  billingUserKey: number
  isTutorInvoice: boolean
  payment?: PaymentInfo
  invoiceKey: number
}

export interface TuitionPaymentAction {
  action: TuitionPaymentActionType
  paymentData?: PaymentModalState
  callback?: () => void
  isDiscount?: boolean
  paymentKey?: number
  invoiceKey?: number
}

export const mapTuitionPaymentsToAmountInputs = (
  tuitionPayments: TuitionPaymentInfo[]
): { [key: string]: string } => {
  return tuitionPayments.reduce((acc, tuitionPayment) => {
    // For each tuitionPayment object, create a combined key using studentKey, programKey and tuitionPaymentKey.
    acc[
      `${tuitionPayment.studentKey}-${tuitionPayment.programKey}-${tuitionPayment.tuitionPaymentKey}`
    ] =
      // Assigns the value of the amount converted to a string as the value of the key in the accumulator object.
      `${tuitionPayment.amount}`
    return acc
  }, {} as { [key: string]: string })
}

const defaultCurrencyCode = 'USD'
const thisYear = new Date().getFullYear()
const recentAcademicYears = [thisYear - 1, thisYear, thisYear + 1]

const FamilyProfile: React.FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const location: {
    state: { triggerRefetch: boolean | undefined }
    pathname: string
  } = useLocation()
  const { permissionAbility, featureAbility } = useAuth()
  const { setSnackbarState, setSnackbarMessage, setSnackbarSeverity } =
    useSnackbarContext()
  const avatarUrl = buildAvatarUrl()
  useSnackbarOnNavigation()
  const navigate = useNavigate()
  const availableLoadingIds = useLoadingIds()
  const { addLoadingIds } = React.useContext(LoadingContext)

  const title = t('FamilyProfile.Title', 'Profile')
  const { useTitleEffect } = useContext(TitleContext)
  useTitleEffect(title)

  const { userId } = useParams<{ userId: string }>()
  const parsedUserId = parseInt(`${userId}`)

  const [isLoadingProfileCard, setIsLoadingProfileCard] = useState(true)
  const [isLoadingStudents, setIsLoadingStudents] = useState(true)
  const [isLoadingPrograms, setIsLoadingPrograms] = useState(true)
  const [isLoadingTuitionBilling, setIsLoadingTuitionBilling] = useState(true)
  const [isLoadingTuitionEnrollments, setIsLoadingTuitionEnrollments] =
    useState(false)

  const [userInfo, setUserInfo] = useState<UserProfile>()
  const [userRolesForCommunities, setUserRolesForCommunities] = useState<
    UserCommunity[]
  >([])
  const [programs, setPrograms] = useState<Program[]>([])
  const [children, setChildren] = useState<Student[]>([])
  const [tuitionCurrencyCode, setTuitionCurrencyCode] =
    useState(defaultCurrencyCode)
  const [tuitionBilling, setTuitionBilling] = useState<BillingInfo[]>([])
  const [tuitionEnrollmentOptions, setTuitionEnrollmentOptions] = useState<
    TuitionEnrollmentOption[]
  >([])

  const billingUserActingOnIsTutor = useRef<boolean>()
  const paymentDataActingOn = useRef<PaymentModalState>()
  const paymentKeyActingOn = useRef<number>()
  const invoiceKeyActingOn = useRef<number>()
  const [isFinalizedEdition, setIsFinalizedEdition] = useState(true)
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)
  const [paymentModalVariantToShow, setPaymentModalVariantToShow] = useState(
    InvoicePaymentModalVariant.AddPayment
  )
  const [isTourFinished, setIsTourFinished] = useState(false)

  const [studentPrograms, setStudentPrograms] = useState<
    AcademicYearPrograms[]
  >([])
  /**
   * We use this to trigger the useEffect for fetching data programmatically.
   * The value itself is not significant, however when the value changes it will trigger the useEffect
   */
  const [triggerRefetchStudents, setTriggerRefetchStudents] = useState(false)
  const [triggerRefetchTuitionInfo, setTriggerRefetchTuitionInfo] =
    useState(false)

  const handleTourFinished = (value: boolean) => {
    setIsTourFinished(value)
  }
  const {
    inputValues,
    changeValueForCalculate,
    result,
    resetCalculatedAmount,
    updateInputValues,
    updateResult,
  } = useCalculateTotalAmount()

  const { triggerRefetch: triggerRefetchAfterSendingInvoice } =
    location.state ?? {}

  const hasInvoiceFeatureFlag = featureAbility.can('invoices', 'Feature')

  const canViewPayment = permissionAbility.can('view', 'Payment')
  const canSendTuitionInvoice = permissionAbility.can(
    'sendTuitionInvoice',
    'Payment'
  )

  const canAddTuitionPayment = permissionAbility.can(
    'addTuitionPayment',
    'Payment'
  )

  const canAddTuitionCredit = permissionAbility.can(
    'addTuitionCredit',
    'Payment'
  )

  const hasProperPermissionForBillingSection = [
    canSendTuitionInvoice,
    canAddTuitionPayment,
    canViewPayment,
  ].some(Boolean)

  const { students } = useAccountContext()

  const commonReqParam = useMemo(() => ({ userId: userId ?? 'me' }), [userId])

  /**
   * Fetch user profile and user community
   */
  useMountEffect(() => {
    addLoadingIds([availableLoadingIds.UserAccounts.fetchUserProfile])
  })

  const getDataForProfileCard = async () => {
    try {
      const fetchedUserProfile = await users.fetchUserProfile(commonReqParam)
      setUserInfo(fetchedUserProfile)

      const fetchedUserCommunities = await users.fetchUserCommunities(
        commonReqParam
      )
      setUserRolesForCommunities(fetchedUserCommunities)
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: (e as unknown as Error).message,
      }
      setSnackbarSeverity?.(SnackbarSeverity.Error)
      setSnackbarMessage?.(errorObject.message)
      setSnackbarState?.(true)
    } finally {
      setIsLoadingProfileCard(false)
    }
  }

  useLoadingContext({
    asyncFunction: getDataForProfileCard,
    loadingId: availableLoadingIds.UserAccounts.fetchUserProfile,
  })

  /**
   * Fetch students for user
   */
  useEffect(() => {
    addLoadingIds([availableLoadingIds.Families.fetchStudentsForFamily])
  }, [
    addLoadingIds,
    availableLoadingIds.Families.fetchStudentsForFamily,
    triggerRefetchStudents,
  ])

  const getStudentsForFamily = async () => {
    try {
      /**
       * A temporary fix to prevent multiple calls to this endpoint when
       * already containing the students for the AccountContext.
       */
      const fetchedStudents = !userId
        ? { students }
        : await families.fetchStudentsForFamily(commonReqParam)
      const studentPrograms: AcademicYearPrograms[] = []
      if (fetchedStudents) {
        const programsAfterFetch: Program[] = []
        const arrayOfDistinctPrograms = new Set<number>()

        fetchedStudents.students.forEach((student) => {
          student.programs.forEach((program) => {
            arrayOfDistinctPrograms.add(program.programKey)
          })
        })

        const responses = await Promise.all(
          Array.from(arrayOfDistinctPrograms).map((programkey: number) => {
            if (programkey) {
              return programApi.fetchProgram({
                programId: programkey,
              })
            } else {
              return [] as unknown as Program
            }
          })
        )
        responses.forEach((response) => {
          programsAfterFetch.push(response)
        })

        //get an array with the programs
        programsAfterFetch.map(async (program: Program) => {
          const utcFullYear = new Date(
            program.semesterOneStartDate
          ).getUTCFullYear()

          const existedStudents = studentPrograms.find(
            (program: AcademicYearPrograms) =>
              program.academicYearBeginning === utcFullYear
          )

          if (existedStudents) {
            existedStudents.programs.push(program)
          } else {
            studentPrograms.push({
              academicYearBeginning: utcFullYear,
              programs: [program],
            })
          }
        })
      }

      studentPrograms && setStudentPrograms(studentPrograms)
      setChildren(fetchedStudents.students)
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: (e as unknown as Error).message,
      }
      setSnackbarSeverity?.(SnackbarSeverity.Error)
      setSnackbarMessage?.(errorObject.message)
      setSnackbarState?.(true)
    } finally {
      setIsLoadingStudents(false)
    }
  }

  useLoadingContext({
    asyncFunction: getStudentsForFamily,
    loadingId: availableLoadingIds.Families.fetchStudentsForFamily,
  })

  /**
   * Fetch programs for user
   */
  useEffect(() => {
    const getProgramsForUser = async () => {
      try {
        const filteredAcademicYearPrograms: AcademicYearPrograms[] =
          studentPrograms.filter((academicYearProgram) =>
            recentAcademicYears.includes(
              academicYearProgram.academicYearBeginning
            )
          )

        const programs = filteredAcademicYearPrograms.reduce(
          (acc, curr): Program[] => [...acc, ...curr.programs],
          [] as Program[]
        )

        setPrograms(programs)
      } catch (e) {
        const errorObject = (await extractedErrorObject(e)) ?? {
          code: 'Unknown',
          message: (e as unknown as Error).message,
        }
        setSnackbarSeverity?.(SnackbarSeverity.Error)
        setSnackbarMessage?.(errorObject.message)
        setSnackbarState?.(true)
      } finally {
        setIsLoadingPrograms(false)
      }
    }

    getProgramsForUser()
  }, [
    commonReqParam,
    setSnackbarSeverity,
    setSnackbarMessage,
    setSnackbarState,
    studentPrograms,
  ])

  /**
   * Fetch tuition billing
   */
  useEffect(() => {
    if (hasProperPermissionForBillingSection) {
      addLoadingIds([availableLoadingIds.Families.fetchFamilyTuitionBilling])
    }
  }, [
    hasProperPermissionForBillingSection,
    triggerRefetchTuitionInfo,
    triggerRefetchAfterSendingInvoice,
    addLoadingIds,
    availableLoadingIds.Families.fetchFamilyTuitionBilling,
  ])

  const getTuitionBilling = async () => {
    try {
      const fetchedTuitionBilling = await families.fetchFamilyTuitionBilling({
        parentUserKey: parsedUserId,
      })

      setTuitionBilling(fetchedTuitionBilling.billingList)
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: (e as unknown as Error).message,
      }
      setSnackbarSeverity?.(SnackbarSeverity.Error)
      setSnackbarMessage?.(errorObject.message)
      setSnackbarState?.(true)
    } finally {
      setIsLoadingTuitionBilling(false)
    }
  }

  useLoadingContext({
    asyncFunction: getTuitionBilling,
    loadingId: availableLoadingIds.Families.fetchFamilyTuitionBilling,
  })

  const refetchStudents = () => {
    // Programmatically trigger a refetch
    setTriggerRefetchStudents((prevState) => !prevState)
  }

  const refetchTuitionInfo = () => {
    // Programmatically trigger a refetch
    setTriggerRefetchTuitionInfo((prevState) => !prevState)
  }

  const handleNavigateToInvoice = ({
    invoiceKey,
    billingUserKey,
    isTutorInvoice,
    showSendInvoiceButton,
  }: {
    invoiceKey: number
    billingUserKey: number
    isTutorInvoice: boolean
    showSendInvoiceButton: boolean
  }) => {
    const basePath = `${location.pathname}/invoice`
    const pathname = !invoiceKey
      ? basePath
      : `${basePath}/${invoiceKey.toString()}`

    navigate(
      {
        pathname,
      },
      {
        state: { billingUserKey, isTutorInvoice, showSendInvoiceButton },
      }
    )
  }

  const getTuitionEnrollmentsFromInvoice = async ({
    billingUserKey,
    isTutorInvoice,
    payment,
    invoiceKey,
  }: TuitionInvoiceQueryParams) => {
    setIsLoadingTuitionEnrollments(true)
    try {
      const { currencyCode, enrollments } =
        await invoicesApi.fetchTuitionInvoice({
          parentUserKey: parsedUserId,
          billingUserKey,
          isTutorInvoice,
          invoiceKey,
        })

      // Build tuition enrollment options
      const tuitionEnrollmentOptions = enrollments.map(
        ({
          programKey,
          programType,
          studentKey,
          studentName,
          fees,
          discounts = [],
          payments = [],
        }): TuitionEnrollmentOption => {
          // Calculate how much each enrollment owes
          const totalFees = sumAmounts(fees)
          const totalDiscounts = sumAmounts(discounts)
          const totalPayments = sumAmounts(payments)
          const total = totalFees - totalDiscounts - totalPayments
          let tuitionPaymentKey = undefined

          if (!!payment) {
            const { tuitionPayments } = payment
            if (tuitionPayments) {
              const indexTuitionPaymentKey = tuitionPayments.findIndex(
                (tuitionEnrollmentOption) =>
                  tuitionEnrollmentOption.studentKey === studentKey &&
                  tuitionEnrollmentOption.programKey === programKey
              )
              tuitionPaymentKey =
                indexTuitionPaymentKey === -1
                  ? undefined
                  : tuitionPayments[indexTuitionPaymentKey].tuitionPaymentKey
            }
          }

          return {
            studentKey,
            studentName,
            programKey,
            programName: programType,
            amountOwed: { amount: total, currencyCode },
            tuitionPaymentKey,
          }
        }
      )

      setTuitionCurrencyCode(currencyCode)
      setTuitionEnrollmentOptions(tuitionEnrollmentOptions)
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: (e as unknown as Error).message,
      }
      setSnackbarSeverity?.(SnackbarSeverity.Error)
      setSnackbarMessage?.(errorObject.message)
      setSnackbarState?.(true)
    } finally {
      setIsLoadingTuitionEnrollments(false)
    }
  }

  const onAddOrEditPaymentClick = (
    variantToShow: InvoicePaymentModalVariant,
    { billingUserKey, isTutorInvoice, invoiceKey }: TuitionInvoiceQueryParams,
    initialData?: PaymentModalState,
    paymentData?: PaymentInfo
  ) => {
    // Store data for modal
    paymentDataActingOn.current = initialData
    paymentKeyActingOn.current = paymentData?.paymentKey
    invoiceKeyActingOn.current = invoiceKey
    InvoicePaymentModalVariant.EditPayment
    // Store data for the field

    if (!!paymentData) {
      // We take the information related to the payment to edit it.
      const { tuitionPayments, amountPaid } = paymentData
      /**
       * we transform the values into valid data for the setInputValues, which represents
       * the values of the amount in the modal
       */
      const dataForAmountInputs = mapTuitionPaymentsToAmountInputs(
        tuitionPayments ?? []
      )

      // set values in useCalculateTotalAmount
      updateInputValues?.(dataForAmountInputs)
      updateResult?.(`${amountPaid.amount}`)
    }

    // Open modal
    setPaymentModalVariantToShow(variantToShow)
    setIsPaymentModalOpen(true)
    // Make api call to fetch enrollment options
    getTuitionEnrollmentsFromInvoice({
      billingUserKey,
      isTutorInvoice,
      payment: paymentData,
      invoiceKey,
    })
  }

  const handleEnterPayment = (
    billingUserKey: number,
    isTutorBilling: boolean,
    variantToShow: InvoicePaymentModalVariant,
    invoiceKey: number
  ) => {
    billingUserActingOnIsTutor.current = isTutorBilling
    onAddOrEditPaymentClick(variantToShow, {
      billingUserKey,
      isTutorInvoice: isTutorBilling,
      invoiceKey,
    })
  }

  const handleViewTuitionPaymentClick = (
    {
      date,
      description,
      isDiscount,
      paymentData,
    }: {
      amount: CashAmount
      date: Date
      description?: string
      isDiscount: boolean
      paymentKey?: number
      paymentData?: PaymentInfo
    },
    { billingUserKey, isTutorInvoice, invoiceKey }: TuitionInvoiceQueryParams
  ) => {
    // Initial data with which to initialize payment modal
    const initialData: PaymentModalState = {
      dateReceived: dateWithTimeToDashString(date),
      description: description ?? '',
    }

    onAddOrEditPaymentClick(
      isDiscount
        ? InvoicePaymentModalVariant.EditDiscount
        : InvoicePaymentModalVariant.EditPayment,
      { billingUserKey, isTutorInvoice, invoiceKey },
      initialData,
      paymentData
    )
  }

  const performTuitionPaymentAction = async ({
    action,
    paymentData,
    callback,
    isDiscount,
    paymentKey,
  }: TuitionPaymentAction) => {
    let tuitionPaymentKeys = Object.keys(inputValues)
    // removes the keys in which an empty amount is being stored
    tuitionPaymentKeys = tuitionPaymentKeys.filter((key) => !!inputValues[key])

    const invoiceKey = invoiceKeyActingOn.current as number

    const payments = tuitionPaymentKeys.map((paymentKey) => {
      const [studentKeyForPayment, programKeyForPayment, tuitionPaymentKey] =
        paymentKey.split('-')

      return {
        studentKey: +studentKeyForPayment,
        programKey: +programKeyForPayment,
        amount: {
          amount: +inputValues[paymentKey],
          currencyCode: tuitionCurrencyCode,
        },
        tuitionPaymentKey: tuitionPaymentKey,
      }
    })

    switch (action) {
      case 'add':
        {
          // Do we have the needed data?
          if (
            !paymentData ||
            typeof billingUserActingOnIsTutor.current === 'undefined'
          )
            return

          const { dateReceived, description } = paymentData

          const invoiceOriginatorKey = billingUserActingOnIsTutor.current
            ? TuitionPaymentsInvoiceOriginatorKeyEnum.Tutor
            : TuitionPaymentsInvoiceOriginatorKeyEnum.Director

          try {
            await tuitionPaymentsApi.addTuitionPayment({
              body: {
                invoiceKey,
                dateReceived: new Date(dateReceived),
                description,
                isDiscount,
                tuitionPayments: payments.map(
                  (payment) =>
                    ({
                      ...payment,
                      invoiceOriginatorKey,
                    } as unknown as TuitionPayments)
                ),
                totalAmount: +result,
              },
            })

            setSnackbarSeverity?.(SnackbarSeverity.Success)
            setSnackbarMessage?.(
              t(
                'TuitionBillingTable.EnterPayment.Success',
                'Payment successfully added.'
              )
            )
            setSnackbarState?.(true)

            // Refresh the tuition table upon success
            refetchTuitionInfo()
          } catch (e) {
            const errorObject = (await extractedErrorObject(e)) ?? {
              code: 'Unknown',
              message: (e as unknown as Error).message,
            }
            setSnackbarSeverity?.(SnackbarSeverity.Error)
            setSnackbarMessage?.(errorObject.message)
            setSnackbarState?.(true)
          }
        }
        break
      case 'edit':
        {
          // Do we have the needed data?
          if (!paymentData || !paymentKeyActingOn.current) return

          const { dateReceived, description } = paymentData

          try {
            await tuitionPaymentsApi.updateInvoiceLine({
              body: {
                invoiceKey,
                paymentKey: paymentKeyActingOn.current,
                dateReceived: new Date(dateReceived),
                description,
                totalAmount: +result,
                isDiscount,
                tuitionPayments: payments.map(
                  (payment) =>
                    ({
                      ...payment,
                    } as unknown as TuitionPayments)
                ),
              },
            })

            setSnackbarSeverity?.(SnackbarSeverity.Success)
            setSnackbarMessage?.(
              t(
                'TuitionBillingTable.EditPayment.Success',
                'Payment successfully edited.'
              )
            )
            setSnackbarState?.(true)

            // Refresh the tuition table upon success
            refetchTuitionInfo()
          } catch (e) {
            const errorObject = (await extractedErrorObject(e)) ?? {
              code: 'Unknown',
              message: (e as unknown as Error).message,
            }
            setSnackbarSeverity?.(SnackbarSeverity.Error)
            setSnackbarMessage?.(errorObject.message)
            setSnackbarState?.(true)
          }
        }
        break
      case 'delete':
        // Do we have the needed data?
        if (!paymentKeyActingOn.current && !paymentKey) return
        try {
          await tuitionPaymentsApi.removeTuitionPayment({
            paymentKey: (paymentKeyActingOn.current ?? paymentKey) as number,
          })

          setSnackbarSeverity?.(SnackbarSeverity.Success)
          setSnackbarMessage?.(
            t(
              'TuitionBillingTable.RemovePayment.Success',
              'Payment successfully removed.'
            )
          )
          setSnackbarState?.(true)

          // Refresh the tuition table upon success
          refetchTuitionInfo()
        } catch (e) {
          const errorObject = (await extractedErrorObject(e)) ?? {
            code: 'Unknown',
            message: (e as unknown as Error).message,
          }
          setSnackbarSeverity?.(SnackbarSeverity.Error)
          setSnackbarMessage?.(errorObject.message)
          setSnackbarState?.(true)
        }

        // This is needed to close the "Are you sure..." confirmation modal
        callback?.()
        break
    }
    setIsPaymentModalOpen(false)
  }

  if (location.pathname.includes(`/family-profile/${userId}/invoice`)) {
    return (
      <Page>
        <CanAccess I="families" on="Feature">
          <Outlet />
        </CanAccess>
      </Page>
    )
  }

  return (
    <Page>
      <CanAccess I="families" on="Feature">
        {!userInfo ? (
          <EmptyFamilyProfile isLoading={isLoadingProfileCard} />
        ) : (
          <>
            <ProfileCard
              userInfo={userInfo}
              userRolesForCommunities={userRolesForCommunities}
              avatarUrl={avatarUrl}
              variant={ProfileCardVariant.Family}
            />
            <PartialPageLoadingSpinner isLoading={isLoadingStudents}>
              <SectionPadding aria-labelledby="children">
                <ChildrenSummaryCardForFamilies
                  kids={children}
                  refetchStudents={refetchStudents}
                />
              </SectionPadding>
            </PartialPageLoadingSpinner>

            <PartialPageLoadingSpinner isLoading={isLoadingPrograms}>
              <SectionPadding aria-labelledby="programs">
                <Header
                  id="programs"
                  headerName={t('Families.Programs.Header', 'Programs')}
                  component="h2"
                  variant={HeaderVariant.Card}
                />
                <ProgramRenderer
                  type={ElevatedCardVariants.Programs}
                  programs={programs}
                />
              </SectionPadding>
            </PartialPageLoadingSpinner>
            {hasInvoiceFeatureFlag && hasProperPermissionForBillingSection && (
              <PartialPageLoadingSpinner isLoading={isLoadingTuitionBilling}>
                <SectionPadding aria-label="tuition billing">
                  {tuitionBilling.map((billingInfo, index) => {
                    const {
                      billingUserFullName,
                      billingUserKey,
                      isTutorBilling,
                      invoices,
                    } = billingInfo

                    const hasPermissionToShowDirectorMenu =
                      canSendTuitionInvoice ||
                      canAddTuitionCredit ||
                      canAddTuitionPayment

                    let directorMenu

                    if (
                      invoices.length > 0 &&
                      hasPermissionToShowDirectorMenu
                    ) {
                      directorMenu = (
                        <InvoiceDirectorMenu
                          billingUserKey={billingUserKey}
                          isTutorBilling={isTutorBilling}
                          /** We should only have one invoice for billingListNow
                           *  that is why we are using the first index in the array.
                           *  todo: endPoint should only return one invoice in place of an array
                           */
                          invoice={invoices[0]}
                          handlePayment={handleEnterPayment}
                          parentEmail={userInfo.username}
                          navigateToInvoice={(showSendInvoiceButton: boolean) =>
                            handleNavigateToInvoice({
                              invoiceKey: invoice.invoiceKey,
                              billingUserKey,
                              isTutorInvoice: isTutorBilling,
                              showSendInvoiceButton,
                            })
                          }
                          addTour={index === 0}
                          handleTourFinished={handleTourFinished}
                        />
                      )
                    }

                    const headerName = isTutorBilling
                      ? t(
                          'FamilyProfile.Header.TutorBilling',
                          'Tutor Billing - {{tutorName}}',
                          {
                            tutorName: billingUserFullName,
                            interpolation: { escapeValue: false },
                          }
                        )
                      : t(
                          'FamilyProfile.Header.Billing',
                          'Billing - {{programDirectorName}}',
                          {
                            programDirectorName: billingUserFullName,
                            interpolation: { escapeValue: false },
                          }
                        )

                    const paymentRows = (invoices[0]?.payments ?? []).map(
                      (payment): TuitionBillingRow => {
                        const isDirectorDiscount =
                          payment.tuitionPayments?.[0]?.isDirectorDiscount ??
                          false

                        const groupedByProgram = (
                          payment.tuitionPayments ?? []
                        ).reduce<Record<string, string[]>>(
                          (acc, { studentName, programType }) => {
                            if (!acc[programType]) {
                              acc[programType] = []
                            }
                            acc[programType].push(studentName)
                            return acc
                          },
                          {}
                        )

                        const programsAndStudents = Object.entries(
                          groupedByProgram
                        ).map(
                          ([programType, studentNames]) =>
                            `${programType} (${studentNames.join(', ')})`
                        )

                        return {
                          date: payment.paymentDate,
                          transaction: isDirectorDiscount
                            ? t(
                                'TuitionBillingTable.Transaction.Discount',
                                '{{discount}}',
                                {
                                  discount: `Discount Applied${
                                    !!payment.paymentCreatorName
                                      ? ` by ${payment.paymentCreatorName}`
                                      : ''
                                  }`,
                                }
                              )
                            : t(
                                'TuitionBillingTable.Transaction.Payment',
                                '{{payment}}',
                                {
                                  payment: `Payment Received${
                                    !!payment.paymentCreatorName
                                      ? ` by ${payment.paymentCreatorName}`
                                      : ''
                                  }`,
                                }
                              ),
                          program: programsAndStudents.join(', '),
                          description: payment.description,
                          amount: payment.amountPaid,
                          isDirectorDiscount,
                          paymentKey: payment.paymentKey,
                          handleClick: () => {
                            handleViewTuitionPaymentClick(
                              {
                                paymentData: payment,
                                amount: payment.amountPaid,
                                date: payment.paymentDate,
                                description: payment.description,
                                isDiscount: isDirectorDiscount,
                              },
                              {
                                billingUserKey,
                                isTutorInvoice: isTutorBilling,
                                invoiceKey: invoice.invoiceKey,
                              }
                            )
                          },
                        }
                      }
                    )
                    const invoice = invoices[0]
                    // Sorted in descending order to show most recent data first
                    const tuitionBillingData = [...paymentRows].sort(
                      (paymentRowA, paymentRowB) =>
                        standardSort(paymentRowB.date, paymentRowA.date)
                    )

                    const uniqueHeaderId = `billingHeader${index}`

                    return (
                      <React.Fragment key={index}>
                        <Box
                          sx={{
                            margin: theme.spacing(3),
                          }}
                        >
                          <Header
                            id={uniqueHeaderId}
                            headerName={headerName}
                            component="h6"
                            variant={
                              index === 0 ? HeaderVariant.Card : undefined
                            }
                          />
                        </Box>
                        <TuitionBillingTable
                          tuitionBillingData={tuitionBillingData}
                          invoiceDirectorMenu={directorMenu}
                          ariaLabelledBy={uniqueHeaderId}
                          performTuitionPaymentAction={
                            performTuitionPaymentAction
                          }
                          invoice={invoice}
                          navigateToInvoice={() =>
                            handleNavigateToInvoice({
                              invoiceKey: invoice.invoiceKey,
                              billingUserKey,
                              isTutorInvoice: isTutorBilling,
                              showSendInvoiceButton:
                                !invoice.lastSentEmailDate ||
                                invoice.lastSentEmailDate <
                                  (invoice.lastInvoiceLineModifiedDate as Date),
                            })
                          }
                          addTour={index === 0}
                          isTourFinished={isTourFinished}
                          handleTourFinished={handleTourFinished}
                        />
                      </React.Fragment>
                    )
                  })}
                </SectionPadding>
              </PartialPageLoadingSpinner>
            )}

            <InvoicePaymentModal
              variant={paymentModalVariantToShow}
              isOpen={isPaymentModalOpen}
              setIsOpen={setIsPaymentModalOpen}
              isLoading={isLoadingTuitionEnrollments}
              clearTuitionEnrollmentOptions={() =>
                setTuitionEnrollmentOptions([])
              }
              tuitionEnrollmentOptions={tuitionEnrollmentOptions}
              performTuitionPaymentAction={performTuitionPaymentAction}
              initialPaymentData={paymentDataActingOn.current}
              name={InvoicePaymentModal.name}
              calculateTotalAmount={{
                result,
                inputValues,
                changeValueForCalculate,
                resetCalculatedAmount,
              }}
              isFinalizedEdition={isFinalizedEdition}
              setIsFinalizedEdition={setIsFinalizedEdition}
              invoiceKeyActingOn={invoiceKeyActingOn}
            />
          </>
        )}
      </CanAccess>
    </Page>
  )
}

export default FamilyProfile
