/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 9c90f423 (Thu Aug 29 18:21:31 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface EditStudentRequestBody
 */
export interface EditStudentRequestBody {
  /**
   * The unique identifier of the student.
   * @type {number}
   * @memberof EditStudentRequestBody
   */
  studentKey: number
  /**
   * The first name of the student.
   * @type {string}
   * @memberof EditStudentRequestBody
   */
  firstName: string
  /**
   * The date of birth of the student.
   * @type {Date}
   * @memberof EditStudentRequestBody
   */
  dateOfBirth?: Date
  /**
   * The number indicating student's month of birth. Indexed from 0 to 11.
   * @type {number}
   * @memberof EditStudentRequestBody
   */
  birthMonth: number
  /**
   * The number indicating student's year of birth.
   * @type {number}
   * @memberof EditStudentRequestBody
   */
  birthYear: number
  /**
   * Indicates if the student is hidden.
   * @type {boolean}
   * @memberof EditStudentRequestBody
   */
  hidden?: boolean
}

export function EditStudentRequestBodyFromJSON(
  json: any
): EditStudentRequestBody {
  return EditStudentRequestBodyFromJSONTyped(json, false)
}

export function EditStudentRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EditStudentRequestBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    studentKey: json['studentKey'],
    firstName: json['firstName'],
    dateOfBirth: !exists(json, 'dateOfBirth')
      ? undefined
      : new Date(json['dateOfBirth']),
    birthMonth: json['birthMonth'],
    birthYear: json['birthYear'],
    hidden: !exists(json, 'hidden') ? undefined : json['hidden'],
  }
}

export function EditStudentRequestBodyToJSON(
  value?: EditStudentRequestBody | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    studentKey: value.studentKey,
    firstName: value.firstName,
    dateOfBirth:
      value.dateOfBirth === undefined
        ? undefined
        : value.dateOfBirth.toISOString().substr(0, 10),
    birthMonth: value.birthMonth,
    birthYear: value.birthYear,
    hidden: value.hidden,
  }
}
