import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'
import { TextButtonVariant } from '../Buttons/TextButton'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import BasicModal from './BasicModal'
import ConfirmationModal from './ConfirmationModal'
import ActionButtons from '../Buttons/ActionButtons'
import AdjacentLabels from '../Labels/AdjacentLabels'
import { dateWithTimeToDashString } from '../../utils/dateUtility'
import getLocaleCurrencyForAmount from '../../utils/getLocaleCurrencyForAmount'
import { CashAmount } from '../../swagger'
import PartialPageLoadingSpinner from '../Elements/PartialPageLoadingSpinner'
import { useAuth } from '../Routes/AuthProvider'
import useLoadingContext from '../../hooks/useLoadingContext'
import { LoadingApiCall } from '../../api/LoadingApiCall'
import { UseCalculate } from '../../hooks/useCalculateTotalAmount'
import { Box } from '@mui/material'

export enum InvoicePaymentModalVariant {
  AddPayment,
  EditPayment,
  AddDiscount,
  EditDiscount,
}

const MAX_CHAR_LIMIT_FOR_DESC = 75

export interface TuitionEnrollmentOption {
  studentKey: number
  studentName: string
  programKey: number
  programName: string
  amountOwed: CashAmount
  tuitionPaymentKey?: number
}
export interface PaymentModalState {
  dateReceived: string
  description: string
}

export type TuitionPaymentActionType = 'add' | 'edit' | 'delete'

const today = new Date()

/**
 * Utility function that builds a formatted string from the given program/student names to display in the UI.
 * @param {string} programName
 * @param {string} studentName
 * @returns {string} Displays an enrollment in the format "Program Name (Student Name)""
 */
export const buildEnrollmentLabel = (
  programName: string,
  studentName: string
): string => `${programName} (${studentName})`

const defaultPaymentData: PaymentModalState = {
  dateReceived: dateWithTimeToDashString(today),
  description: '',
}

export interface InvoicePaymentModalProps {
  variant: InvoicePaymentModalVariant
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  isLoading?: boolean
  tuitionEnrollmentOptions: TuitionEnrollmentOption[]
  clearTuitionEnrollmentOptions: () => void
  performTuitionPaymentAction: ({
    action,
    paymentData,
    callback,
    isDiscount,
  }: {
    action: TuitionPaymentActionType
    paymentData?: PaymentModalState
    callback?: () => void
    isDiscount: boolean
  }) => Promise<void>
  initialPaymentData?: PaymentModalState
  name: string
  calculateTotalAmount: Omit<UseCalculate, 'updateResult' | 'updateInputValues'>
  isFinalizedEdition: boolean
  setIsFinalizedEdition: React.Dispatch<React.SetStateAction<boolean>>
  invoiceKeyActingOn: React.MutableRefObject<number | undefined>
}

const InvoicePaymentModal: React.FC<InvoicePaymentModalProps> = ({
  variant,
  isOpen,
  setIsOpen,
  initialPaymentData,
  isLoading,
  tuitionEnrollmentOptions,
  clearTuitionEnrollmentOptions,
  performTuitionPaymentAction,
  name,
  calculateTotalAmount,
  isFinalizedEdition,
  setIsFinalizedEdition,
  invoiceKeyActingOn,
}) => {
  const { t } = useTranslation()

  const { permissionAbility } = useAuth()
  const {
    result,
    inputValues,
    changeValueForCalculate,
    resetCalculatedAmount,
  } = calculateTotalAmount
  const [paymentData, setPaymentData] = useState(defaultPaymentData)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

  // Update current state if initial values are passed in
  useEffect(() => {
    setPaymentData(initialPaymentData ?? defaultPaymentData)
  }, [initialPaymentData])

  const isAddVariant =
    variant === InvoicePaymentModalVariant.AddPayment ||
    variant === InvoicePaymentModalVariant.AddDiscount
  const isDiscount =
    variant === InvoicePaymentModalVariant.AddDiscount ||
    variant === InvoicePaymentModalVariant.EditDiscount

  const expectedKeyParts = 3

  const closeModal = () => {
    setIsOpen(false)
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = event.target

    // update paymentData state as normal
    setPaymentData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const cleanUp = () => {
    setPaymentData(initialPaymentData ?? defaultPaymentData)
    if (isFinalizedEdition) {
      clearTuitionEnrollmentOptions()
      resetCalculatedAmount?.()
      invoiceKeyActingOn.current = undefined
    }
  }

  const deletePaymentClick = () => {
    setIsFinalizedEdition(false)
    closeModal()
    setIsConfirmationOpen(true)
  }

  const confirmationCancel = () => {
    setIsFinalizedEdition(true)
    setIsConfirmationOpen(false)
    setIsOpen(true)
  }

  const canAdd = permissionAbility.can(
    isDiscount ? 'addTuitionCredit' : 'addTuitionPayment',
    'Payment'
  )
  const canDelete = permissionAbility.can(
    isDiscount ? 'deleteTuitionCredit' : 'deleteTuitionPayment',
    'Payment'
  )
  const canUpdate = permissionAbility.can(
    isDiscount ? 'updateTuitionCredit' : 'updateTuitionPayment',
    'Payment'
  )
  // make sure that the fields that have tuitionPayment are valid
  const inputValidateForUpdate = () => {
    // Filter keys that follow the pattern 'studentKey-programKey-tuitionPaymentKey'
    const keys = Object.keys(inputValues).filter((key) => {
      return key.split('-').length === expectedKeyParts
    })
    // at this point zero (0) is a valid value
    return keys.some((value) => inputValues[value] === '')
  }

  const disablePrimaryButton =
    (isAddVariant && !canAdd) ||
    (!isAddVariant && !canUpdate) ||
    [!result, !paymentData.description].some(Boolean) ||
    (!isAddVariant ? inputValidateForUpdate() : false) // if we are editing it should not allow us to save empty values

  const cancelAction = {
    label: TextButtonVariant.Cancel,
    onClick: closeModal,
  }

  const deleteAction = {
    label: isDiscount
      ? TextButtonVariant.DeleteDiscount
      : TextButtonVariant.DeletePayment,
    onClick: deletePaymentClick,
  }

  const addVariantActionProps = {
    primaryButtonLabel: isDiscount
      ? ContainedButtonVariant.AddDiscount
      : ContainedButtonVariant.AddPayment,
    secondaryButtonLabel: cancelAction.label,
    secondaryClick: cancelAction.onClick,
    disablePrimaryButton,
  }

  const editVariantActionProps = {
    primaryButtonLabel: isDiscount
      ? ContainedButtonVariant.UpdateDiscount
      : ContainedButtonVariant.UpdatePayment,
    secondaryButtonLabel: canDelete ? deleteAction.label : undefined,
    disableSecondaryButton: canDelete && isLoading,
    secondaryClick: canDelete
      ? deleteAction.onClick
      : () => {
          return
        },
    tertiaryButtonLabel: cancelAction.label,
    tertiaryClick: cancelAction.onClick,
    disablePrimaryButton: isLoading ? isLoading : disablePrimaryButton,
  }

  const actionButtonProps = isAddVariant
    ? addVariantActionProps
    : editVariantActionProps

  const action = isAddVariant ? 'add' : 'edit'

  const modalTitle = !isDiscount
    ? isAddVariant
      ? t('InvoicePaymentModal.Title.AddPayment', 'Enter a Payment Received')
      : t('InvoicePaymentModal.Title.EditPayment', 'Payment Received')
    : isAddVariant
    ? t('InvoicePaymentModal.Title.AddDiscount', 'Enter a Discount to Apply')
    : t('InvoicePaymentModal.Title.EditDiscount', 'Discount Applied')

  const confirmationModalTitle = !isDiscount
    ? t(
        'Invoice.ConfirmationModal.DeletePayment',
        'Are you sure you want to delete this payment?'
      )
    : t(
        'Invoice.ConfirmationModal.DeleteDiscount',
        'Are you sure you want to delete this discount?'
      )

  const isEnrollmentOptionsEmpty = !tuitionEnrollmentOptions.length

  const getIdByAction = () => {
    switch (action) {
      case 'add':
        return `${LoadingApiCall.AddTuitionPayment}${name}`
      case 'edit':
        return `${LoadingApiCall.UpdateTuitionPayment}${name}`
      default:
        return name
    }
  }

  /**
   * Asynchronously handle the formSubmission from this BasicModal.
   */
  useLoadingContext({
    asyncFunction: async () =>
      await performTuitionPaymentAction({ action, paymentData, isDiscount }),
    loadingId: getIdByAction(),
  })

  return (
    <>
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        ariaLabel={confirmationModalTitle}
        dialogTitle={confirmationModalTitle}
        dialogContent={null}
        dialogActions={
          <ActionButtons
            primaryButtonLabel={ContainedButtonVariant.YesDelete}
            secondaryButtonLabel={TextButtonVariant.NoCancel}
            secondaryClick={confirmationCancel}
          />
        }
        handleFormSubmit={async (e) => {
          e.preventDefault()
          await performTuitionPaymentAction({
            action: 'delete',
            callback: () => {
              setIsConfirmationOpen(false)
              clearTuitionEnrollmentOptions()
              resetCalculatedAmount?.()
              setIsFinalizedEdition(true)
            },
            isDiscount,
          })
        }}
      />
      <BasicModal
        isOpen={isOpen}
        maxWidth="xs"
        handleFormSubmit={(e) => {
          e.preventDefault()
        }}
        ariaLabel={modalTitle}
        dialogTitle={modalTitle}
        dialogContent={
          <>
            <PartialPageLoadingSpinner
              key={'PartialPageLoadingSpinnerkey'}
              isLoading={!!isLoading}
              height={50}
            />
            {!isLoading && !isEnrollmentOptionsEmpty && (
              <>
                {tuitionEnrollmentOptions.map(
                  (
                    {
                      studentName,
                      programName,
                      programKey,
                      studentKey,
                      amountOwed: { amount, currencyCode },
                      tuitionPaymentKey,
                    },
                    index
                  ) => {
                    let inputName = `${studentKey}-${programKey}`

                    if (!!tuitionPaymentKey) {
                      inputName = `${inputName}-${tuitionPaymentKey}`
                    }

                    return (
                      <Box key={tuitionPaymentKey ?? `key-${index}Program`}>
                        <AdjacentLabels
                          key={index}
                          leftLabel={`${buildEnrollmentLabel(
                            programName,
                            studentName
                          )} Due`}
                          rightLabel={getLocaleCurrencyForAmount(
                            amount,
                            currencyCode
                          )}
                        />
                        <TextField
                          key={`key-${inputName}`}
                          name={inputName}
                          label={t(
                            'Invoice.PaymentModal.Program.Amount',
                            'Amount'
                          )}
                          InputLabelProps={{
                            id: inputName,
                          }}
                          aria-labelledby={inputName}
                          fullWidth
                          margin="normal"
                          variant="filled"
                          value={inputValues[inputName] ?? ''}
                          onChange={changeValueForCalculate}
                          disabled={!canUpdate}
                        />
                      </Box>
                    )
                  }
                )}
                <TextField
                  id="paymentAmount"
                  name="amountApplied"
                  label={t(
                    'Invoice.PaymentModal.AmountApplied',
                    'Amount Applied'
                  )}
                  fullWidth
                  margin="normal"
                  variant="filled"
                  value={result}
                  disabled
                />
                {!isDiscount && (
                  <TextField
                    id="paymentDate"
                    name="dateReceived"
                    label={t(
                      'Invoice.PaymentModal.DatePaymentReceived',
                      'Date Payment Received'
                    )}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="datetime-local"
                    margin="normal"
                    variant="filled"
                    value={paymentData.dateReceived}
                    onChange={handleInputChange}
                    disabled={!canUpdate}
                  />
                )}

                <TextField
                  id="paymentDesc"
                  name="description"
                  label={t('Invoice.PaymentModal.Description', 'Description')}
                  fullWidth
                  multiline
                  rows={3}
                  margin="normal"
                  variant="filled"
                  inputProps={{ maxLength: MAX_CHAR_LIMIT_FOR_DESC }}
                  helperText={t(
                    'Invoice.PaymentModal.Description.HelperText',
                    '75 character max'
                  )}
                  value={paymentData.description}
                  onChange={handleInputChange}
                  disabled={!canUpdate}
                />
              </>
            )}
          </>
        }
        dialogActions={
          <ActionButtons
            alwaysStack
            {...actionButtonProps}
            useBaseButton
            primaryButtonLoadingId={getIdByAction()}
          />
        }
        afterClose={cleanUp}
      />
    </>
  )
}

export default InvoicePaymentModal
