/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 9c90f423 (Thu Aug 29 18:21:31 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  EnrollmentStatus,
  EnrollmentStatusFromJSON,
  EnrollmentStatusToJSON,
} from './'

/**
 * All possible statuses.
 * @export
 * @interface EnrollmentStatusesResponse
 */
export interface EnrollmentStatusesResponse {
  /**
   * All of the statuses an enrollment can have.
   * @type {Array<EnrollmentStatus>}
   * @memberof EnrollmentStatusesResponse
   */
  statuses: Array<EnrollmentStatus>
}

export function EnrollmentStatusesResponseFromJSON(
  json: any
): EnrollmentStatusesResponse {
  return EnrollmentStatusesResponseFromJSONTyped(json, false)
}

export function EnrollmentStatusesResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnrollmentStatusesResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    statuses: (json['statuses'] as Array<any>).map(EnrollmentStatusFromJSON),
  }
}

export function EnrollmentStatusesResponseToJSON(
  value?: EnrollmentStatusesResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    statuses: (value.statuses as Array<any>).map(EnrollmentStatusToJSON),
  }
}
