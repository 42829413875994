/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 9c90f423 (Thu Aug 29 18:21:31 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface PaymentConfiguration
 */
export interface PaymentConfiguration {
  /**
   * The code describing this payment configuration. nsfFee: Amount to charge against the account when an NSF fee is applied. achDiscount: Amount to reduce charges against the account by when payment is made by ACH.
   * @type {string}
   * @memberof PaymentConfiguration
   */
  code: PaymentConfigurationCodeEnum
  /**
   * Total monetary amount for this payment configuration.
   * @type {number}
   * @memberof PaymentConfiguration
   */
  amount: number
}

/**
 * @export
 * @enum {string}
 */
export enum PaymentConfigurationCodeEnum {
  NsfFee = 'nsfFee',
  AchDiscount = 'achDiscount',
}

export function PaymentConfigurationFromJSON(json: any): PaymentConfiguration {
  return PaymentConfigurationFromJSONTyped(json, false)
}

export function PaymentConfigurationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PaymentConfiguration {
  if (json === undefined || json === null) {
    return json
  }
  return {
    code: json['code'],
    amount: json['amount'],
  }
}

export function PaymentConfigurationToJSON(
  value?: PaymentConfiguration | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    code: value.code,
    amount: value.amount,
  }
}
