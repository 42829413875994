/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 9c90f423 (Thu Aug 29 18:21:31 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  AgreementTemplateFormFieldInputType,
  AgreementTemplateFormFieldInputTypeFromJSON,
  AgreementTemplateFormFieldInputTypeToJSON,
} from './'

/**
 *
 * @export
 * @interface AgreementTemplateFormField
 */
export interface AgreementTemplateFormField {
  /**
   * The name for this input field, which also serves as the unique identifier for this form field.
   * @type {string}
   * @memberof AgreementTemplateFormField
   */
  inputName: string
  /**
   * The name for this input field to display in the UI.
   * @type {string}
   * @memberof AgreementTemplateFormField
   */
  displayName: string
  /**
   * Whether the field is required to complete the Adobe Sign Agreement.
   * @type {boolean}
   * @memberof AgreementTemplateFormField
   */
  required: boolean
  /**
   *
   * @type {AgreementTemplateFormFieldInputType}
   * @memberof AgreementTemplateFormField
   */
  inputType: AgreementTemplateFormFieldInputType
  /**
   * Helper text for the form field.
   * @type {string}
   * @memberof AgreementTemplateFormField
   */
  tooltip: string
  /**
   * The default value for this form field defined within Adobe Sign.
   * @type {string}
   * @memberof AgreementTemplateFormField
   */
  defaultValue: string
  /**
   * The minimum number of characters for a TEXT_FIELD form field. Excluded if no min length.
   * @type {number}
   * @memberof AgreementTemplateFormField
   */
  minTextLength?: number
  /**
   * The maximum number of characters for a TEXT_FIELD form field. Excluded if no max length.
   * @type {number}
   * @memberof AgreementTemplateFormField
   */
  maxTextLength?: number
  /**
   *
   * @type {Array<string>}
   * @memberof AgreementTemplateFormField
   */
  dropdownSelectOptions?: Array<string>
}

export function AgreementTemplateFormFieldFromJSON(
  json: any
): AgreementTemplateFormField {
  return AgreementTemplateFormFieldFromJSONTyped(json, false)
}

export function AgreementTemplateFormFieldFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgreementTemplateFormField {
  if (json === undefined || json === null) {
    return json
  }
  return {
    inputName: json['inputName'],
    displayName: json['displayName'],
    required: json['required'],
    inputType: AgreementTemplateFormFieldInputTypeFromJSON(json['inputType']),
    tooltip: json['tooltip'],
    defaultValue: json['defaultValue'],
    minTextLength: !exists(json, 'minTextLength')
      ? undefined
      : json['minTextLength'],
    maxTextLength: !exists(json, 'maxTextLength')
      ? undefined
      : json['maxTextLength'],
    dropdownSelectOptions: !exists(json, 'dropdownSelectOptions')
      ? undefined
      : json['dropdownSelectOptions'],
  }
}

export function AgreementTemplateFormFieldToJSON(
  value?: AgreementTemplateFormField | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    inputName: value.inputName,
    displayName: value.displayName,
    required: value.required,
    inputType: AgreementTemplateFormFieldInputTypeToJSON(value.inputType),
    tooltip: value.tooltip,
    defaultValue: value.defaultValue,
    minTextLength: value.minTextLength,
    maxTextLength: value.maxTextLength,
    dropdownSelectOptions: value.dropdownSelectOptions,
  }
}
