import React, { useEffect, useRef, useState } from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'
import {
  dateToSlashString,
  dateToSlashStringReinterpretedAsLocal,
} from '../../utils/dateUtility'
import { useLocation, useNavigate, useParams } from 'react-router'
import SearchBar from '../Search/SearchBar'
import { EnrollmentInviteStatusEnum, OrderByDirection } from '../../swagger'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import TextButton, { TextButtonVariant } from '../Buttons/TextButton'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import { extractedErrorObject, families } from '../../api/swagger'
import ConfirmationModal from '../Modals/ConfirmationModal'
import ActionButtons from '../Buttons/ActionButtons'
import EnrollmentInviteRowInfo from '../Interfaces/EnrollmentInviteRowInfo'
import DropDown, { DropDownVariant } from '../Menus/DropDown'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { validateEmailText } from '../../helpers/validateEmail'
import useEmailMessage from '../../hooks/useEmailMessage'
import Box from '@mui/material/Box'
import { styled } from '@mui/system'
import { useAuth } from '../Routes/AuthProvider'
import {
  ENROLLMENT_INVITES_MIN_SEARCH_LENGTH,
  enrollmentInviteStatusFilterOptions,
  useProgramDetailsContext,
} from '../Context/ProgramDetailsContext'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import useLoadingContext from '../../hooks/useLoadingContext'
import {
  ActionableTable,
  ActionableTableColumn,
} from '../Table/ActionableTable'
import { GridCellParams, GridValueFormatterParams } from '@mui/x-data-grid'
import { Action } from '../Table/RowActions'
import { PaginationResponse } from '../../swagger/models/PaginationResponse'
import { GlobalStyles } from '@mui/material'
import { LoadingContext } from '../Context/LoadingContext'
import EmptyPage from '../Elements/EmptyPage'
import { ProgramDetailsTab } from './ProgramDetails'
import LoadingProgress from '../Elements/LoadingProgress'

const Section = styled('section')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

const ItalicSpan = styled('span')({
  fontStyle: 'italic',
})

enum ConfirmationModalVariant {
  Resend,
  Cancel,
}

export interface InputProperties {
  value: string
  error: string
  valid: boolean
  initialValue: string
}

interface EnrollmentsInviteTableProps {
  enrollmentsInvite?: EnrollmentInviteRowInfo[]
  refetchEnrollmentsData?: () => void
  updateSelectedTab?: (tab: string) => void
  isTabInvitesTourCompleted?: boolean
}

export const EnrollmentsInviteTable: React.FC<EnrollmentsInviteTableProps> = ({
  isTabInvitesTourCompleted,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { validationMessages } = useEmailMessage()
  const { setSnackbarSeverity, setSnackbarMessage, setSnackbarState } =
    useSnackbarContext()
  const { permissionAbility } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const showOnDesktop = useShowOnDesktop()
  const familyNameActingOn = useRef('')
  const invitationIdActingOn = useRef('')
  const { EnrollmentsInviteTable } = useLoadingIds()
  const primaryButtonLoadingId = EnrollmentsInviteTable.resendInvitation
  const cancelInvitationLoadingIds = EnrollmentsInviteTable.cancelInvitation
  const { addLoadingIds, loadingIds } = React.useContext(LoadingContext)

  const InitialStateSentEmail: InputProperties = {
    value: '',
    error: '',
    valid: false,
    initialValue: '',
  }

  /** State Objects */
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [confirmationModalVariant, setConfirmationModalVariant] = useState(
    ConfirmationModalVariant.Resend
  )

  const [sentEmail, setSentEmail] = useState<InputProperties>(
    InitialStateSentEmail
  )
  const { programKey } = useParams<{ programKey: string }>()
  const programId = parseInt(`${programKey}`)
  const fileName = 'EnrollmentsInviteTable'
  const fetchEnrollmentInvitesLoadingId = `${fileName}-${EnrollmentsInviteTable.fetchEnrollmentInvites}`

  const {
    updateEnrollmentInviteFilters,
    updateSearchEnrollmentInvites,
    handleEnrollmentInvitesPagination,
    fetchEnrollmentInvites: fetchInvites,

    searchEnrollmentInvites,
    enrollmentInvites,
    enrollmentInviteFilters,
    enrollmentInvitesPagination,
    isSearchingOrFilteringEnrollmentInvites,
  } = useProgramDetailsContext()

  const fetchEnrollmentInvites = async () => {
    await fetchInvites(programId)
  }

  useEffect(() => {
    addLoadingIds([fetchEnrollmentInvitesLoadingId])
  }, [
    searchEnrollmentInvites,
    enrollmentInviteFilters,
    fetchEnrollmentInvitesLoadingId,
    enrollmentInvitesPagination.page,
    enrollmentInvitesPagination.pageSize,
    enrollmentInvitesPagination.orderBy,
    addLoadingIds,
  ])

  useLoadingContext({
    loadingId: fetchEnrollmentInvitesLoadingId,
    asyncFunction: fetchEnrollmentInvites,
  })

  const refetchEnrollmentsData = () => {
    if (permissionAbility.can('view', 'Family')) {
      addLoadingIds([fetchEnrollmentInvitesLoadingId])
    }
  }

  const getLabelForInviteStatus = (options: {
    inviteStatus?: string
  }): string => {
    let label = ''
    switch (options.inviteStatus) {
      case enrollmentInviteStatusFilterOptions.AllStatus:
      default:
        label = t(
          'EnrollmentsInvite.FilterOption.AllInviteStatus',
          'All Invite Statuses'
        )
        break
      case enrollmentInviteStatusFilterOptions.InProgress:
        label = t('EnrollmentsInvite.FilterOption.InProgress', 'In Progress')
        break
      case enrollmentInviteStatusFilterOptions.Complete:
        label = t('EnrollmentsInvite.FilterOption.Complete', 'Accepted')
        break
      case enrollmentInviteStatusFilterOptions.Cancelled:
        label = t('EnrollmentsInvite.FilterOption.Cancelled', 'Cancelled')
        break
    }
    return label
  }

  const addPointerCell = (params: GridCellParams): string => {
    if (
      !!params.row.primaryContactId &&
      params.row.enrollmentInviteStatus === EnrollmentInviteStatusEnum.Complete
    ) {
      // this custom class allows us to add the cursor pointer to the cells
      return 'MuiDataGrid-cell-pointer'
    }
    return ''
  }

  // action Headers
  const tableActionHeaders: ActionableTableColumn[] = [
    {
      fieldName: 'familyName',
      columnHeaderName: t(
        'Enrollments.Invite.Table.FamilyName.Header',
        'Family Name'
      ),
      cellClassName: addPointerCell,
    },
    {
      fieldName: 'sentToEmail',
      columnHeaderName: t(
        'Enrollments.Invite.Table.SentEmail.Header',
        'Sent Email'
      ),
      cellClassName: addPointerCell,
    },
    {
      fieldName: 'inviteDate',
      columnHeaderName: t(
        'Enrollments.Invite.Table.InviteDate.Header',
        'Invite Date'
      ),
      cellClassName: addPointerCell,
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        return dateToSlashStringReinterpretedAsLocal(params.value)
      },
      sortable: true,
      sortingOrder: ['desc', 'asc'],
    },
    {
      fieldName: 'resentDate',
      columnHeaderName: t(
        'Enrollments.Invite.Table.ResentDate.Header',
        'Re-sent Date'
      ),
      cellClassName: addPointerCell,
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        return !!params.value
          ? dateToSlashStringReinterpretedAsLocal(params.value)
          : ''
      },
      description: t(
        'Enrollments.Invite.Table.ResentDate.HeaderDescription',
        'Date when this invitation was last resent'
      ),
    },
    {
      fieldName: 'offeredSpots',
      columnHeaderName: t(
        'Enrollments.Invite.Table.OfferedSpot.Header',
        'Offered Spots'
      ),
      cellClassName: addPointerCell,
      description:
        t(
          'Enrollments.Invite.Table.OfferedSpot.HeaderDescription',
          'Total Offered Spots: '
        ) +
        enrollmentInvites.reduce((acc, curr) => {
          return acc + curr.offeredSpots
        }, 0),
    },
    {
      fieldName: 'skippedSpots',
      columnHeaderName: t(
        'Enrollments.Invite.Table.SkippedSpots.Header',
        'Skipped Spots'
      ),
      cellClassName: addPointerCell,
      description:
        t(
          'Enrollments.Invite.Table.OfferedSpot.HeaderDescription',
          'Total Skipped Spots: '
        ) +
        enrollmentInvites.reduce((acc, curr) => {
          return acc + (curr?.skippedSpots ?? 0)
        }, 0),
    },
    {
      fieldName: 'enrollmentInviteStatus',
      columnHeaderName: t(
        'Enrollments.Invite.Table.InviteStatus.Header',
        'Status'
      ),
      cellClassName: addPointerCell,
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        return getLabelForInviteStatus({
          inviteStatus: params.value,
        })
      },
    },
  ]

  const handleCancel = (row: EnrollmentInviteRowInfo) => {
    setConfirmationModalVariant(ConfirmationModalVariant.Cancel)
    setShowConfirmationModal(true)
    familyNameActingOn.current = row.familyName
    invitationIdActingOn.current = row.rowId
  }

  const clickableCell = (
    enrollmentInvite: EnrollmentInviteRowInfo,
    field: string
  ) => {
    if (field !== 'actions') {
      !!enrollmentInvite.primaryContactId &&
        enrollmentInvite.enrollmentInviteStatus ===
          EnrollmentInviteStatusEnum.Complete &&
        navigateToFamilyProfile(enrollmentInvite.primaryContactId)
    }
  }

  const handlePagination = (pagination: PaginationResponse) => {
    if (enrollmentInvitesPagination.page < 1) return
    handleEnrollmentInvitesPagination?.(pagination)
  }

  const rowActions: Action<EnrollmentInviteRowInfo>[] = [
    {
      actionName: t(
        'Enrollments.Invite.Table.RowAction.EditAndResend',
        'Edit & Resend'
      ),
      actionKey: 'edit',
      actionFunction: (enrollmentRow) =>
        handleResendClick(
          enrollmentRow ?? ([] as unknown as EnrollmentInviteRowInfo)
        ),
      hide: (enrollmentRow) => {
        const isEditAndResend =
          enrollmentRow?.enrollmentInviteStatus ===
          EnrollmentInviteStatusEnum.InProgress
        return !isEditAndResend
      },
    },
  ]
  if (permissionAbility.can('allowCancelInvite', 'Family')) {
    rowActions.push({
      actionName: t('Enrollments.Invite.Table.RowAction.Cancel', 'Cancel'),
      actionKey: 'cancel',
      actionFunction: (enrollmentRow) =>
        handleCancel(
          enrollmentRow ?? ([] as unknown as EnrollmentInviteRowInfo)
        ),
      hide: (enrollmentRow) => {
        const isEditAndResend =
          enrollmentRow?.enrollmentInviteStatus ===
          EnrollmentInviteStatusEnum.InProgress
        return !isEditAndResend
      },
    })
  }

  /** Methods */
  const navigateToFamilyProfile = (userId: number) => {
    navigate(
      {
        pathname: `/family-profile/${userId.toString()}`,
      },
      {
        /** Navigation Options */
      }
    )
  }

  const handleSearch = (searchText: string) => {
    if (searchText.length >= ENROLLMENT_INVITES_MIN_SEARCH_LENGTH) {
      updateSearchEnrollmentInvites(searchText)
    }
    if (searchText.length === 0) {
      updateSearchEnrollmentInvites('')
    }
  }

  const handleResendInvitation = async () => {
    let snackbarMessage: string
    let snackbarSeverity: SnackbarSeverity

    try {
      await families.resendInvitation({
        enrollmentInviteUuid: invitationIdActingOn.current,
        body:
          sentEmail.initialValue === sentEmail.value
            ? {}
            : { sentToEmail: sentEmail.value },
      })
      refetchEnrollmentsData?.()
      const successMessage = t(
        'EnrollmentsInviteTable.ResendInvitation',
        'Invitation resent'
      )
      snackbarMessage = successMessage
      snackbarSeverity = SnackbarSeverity.Success
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: (e as unknown as Error).message,
      }
      snackbarMessage = errorObject.message
      snackbarSeverity = SnackbarSeverity.Error
    }
    setSnackbarState?.(true)
    setSnackbarMessage?.(snackbarMessage)
    setSnackbarSeverity?.(snackbarSeverity)

    setShowConfirmationModal(false)
  }

  const handleResendInvitationSubmit = async (
    event: React.FormEvent<HTMLDivElement>
  ) => {
    event.preventDefault()
  }

  useLoadingContext({
    asyncFunction: handleResendInvitation,
    loadingId: primaryButtonLoadingId,
  })

  const sharedDropDownProps = {
    variant: DropDownVariant.SortAndFilter,
    fullWidth: !showOnDesktop,
  }

  const handleCancelInvitation = async () => {
    try {
      await families.cancelInvitation({ uuid: invitationIdActingOn.current })
      refetchEnrollmentsData?.()
      const successMessage = t(
        'EnrollmentsInviteTable.CancelInvitation',
        'Invitation cancelled'
      )
      setSnackbarState?.(true)
      setSnackbarMessage?.(successMessage)
      setSnackbarSeverity?.(SnackbarSeverity.Success)
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: (e as unknown as Error).message,
      }
      setSnackbarState?.(true)
      setSnackbarMessage?.(errorObject.message)
      setSnackbarSeverity?.(SnackbarSeverity.Error)
    } finally {
      setShowConfirmationModal(false)
    }
  }

  const handleCancelInvitationSubmit = async (
    event: React.FormEvent<HTMLDivElement>
  ) => {
    event.preventDefault()
  }

  useLoadingContext({
    asyncFunction: handleCancelInvitation,
    loadingId: cancelInvitationLoadingIds,
  })

  const handleConfirmationCancel = async () => {
    setShowConfirmationModal(false)
    setSentEmail(InitialStateSentEmail)
  }

  const handleInviteStatusFilterSelection = (
    _: string,
    __: string,
    selectionOptionId: string | number
  ) => {
    updateEnrollmentInviteFilters({
      ...enrollmentInviteFilters,
      inviteStatus: `${selectionOptionId}`,
    })
  }

  const handleChangeSentEmail = (email: string) => {
    email = email.trim()
    const errorEmailCode = validateEmailText(email)

    setSentEmail({
      ...sentEmail,
      value: email,
      error: validationMessages(errorEmailCode),
      valid: !!errorEmailCode,
    })
  }

  const resendMessage = t(
    'EnrollmentsInviteTable.ConfirmationModal.Header.Resend',
    'Resend'
  )
  const cancelMessage = t(
    'EnrollmentsInviteTable.ConfirmationModal.Header.Cancel',
    'Cancel'
  )
  const baseConfirmationHeaderMessage = `${t(
    'EnrollmentsInviteTable.ConfirmationModal.Header.Base',
    'this invitation to'
  )} ${familyNameActingOn.current}?`

  const confirmationBody = (
    <>
      <Typography
        variant="body1"
        component="p"
        align="center"
        margin={theme.spacing(1)}
      >
        {t(
          'EnrollmentsInviteTable.ConfirmationModal.Body.Resend.Invitation',
          'This will resend the same invitation to the family.'
        )}
      </Typography>
      <Typography
        variant="body1"
        component="p"
        align="center"
        margin={theme.spacing(1)}
      >
        {t(
          'EnrollmentsInviteTable.ConfirmationModal.Body.Resend.ReviewEmail',
          'Please review and/or modify the email address below to ensure it is correct.'
        )}
      </Typography>
      <Typography
        variant="body1"
        component="p"
        align="center"
        margin={theme.spacing(1)}
      >
        {t(
          'EnrollmentsInviteTable.ConfirmationModal.Body.Resend.Label',
          'For '
        )}
        <ItalicSpan>
          {t(
            'EnrollmentsInviteTable.ConfirmationModal.Body.Resend.Span',
            'returning families, '
          )}
        </ItalicSpan>
        {t(
          'EnrollmentsInviteTable.ConfirmationModal.Body.Resend.CCEmail',
          'please ensure the email address matches their CC Connected account.'
        )}
      </Typography>
    </>
  )

  const resendConfirmationProps = {
    isOpen: showConfirmationModal,
    dialogTitle: `${resendMessage} ${baseConfirmationHeaderMessage}`,
    dialogContent: confirmationBody,
    dialogActions: (
      <ActionButtons
        primaryButtonLoadingId={primaryButtonLoadingId}
        primaryButtonLabel={ContainedButtonVariant.YesResend}
        secondaryButtonLabel={TextButtonVariant.NoClose}
        secondaryClick={handleConfirmationCancel}
        useBaseButton
      />
    ),
    handleFormSubmit: handleResendInvitationSubmit,
    inputProperties: sentEmail,
    handleInputChange: handleChangeSentEmail,
    viewEntrySentToEmail: true,
    nameInput: 'Sent Email',
  }

  const cancelConfirmationProps = {
    isOpen: showConfirmationModal,
    dialogTitle: `${cancelMessage} ${baseConfirmationHeaderMessage}`,
    dialogContent: (
      <Typography variant="body1" component="p" align="center">
        {t(
          'EnrollmentsInviteTable.ConfirmationModal.Body.Cancel',
          'Are you sure you wish to cancel invitation? All programs associated with this invitation will be cancelled. Do you wish to continue?'
        )}
      </Typography>
    ),
    dialogActions: (
      <ActionButtons
        primaryButtonLoadingId={cancelInvitationLoadingIds}
        primaryButtonLabel={ContainedButtonVariant.YesCancel}
        secondaryButtonLabel={TextButtonVariant.NoClose}
        secondaryClick={handleConfirmationCancel}
        useBaseButton
      />
    ),
    handleFormSubmit: handleCancelInvitationSubmit,
  }

  const handleResendClick = (it: EnrollmentInviteRowInfo) => {
    setConfirmationModalVariant(ConfirmationModalVariant.Resend)
    setShowConfirmationModal(true)
    familyNameActingOn.current = it.familyName
    invitationIdActingOn.current = it.rowId
    setSentEmail({
      ...sentEmail,
      value: it.sentToEmail,
      initialValue: it.sentToEmail,
    })
  }

  const confirmationProps =
    confirmationModalVariant === ConfirmationModalVariant.Resend
      ? resendConfirmationProps
      : cancelConfirmationProps

  const changeTab = () => {
    // we are going to reload only if we are on mobile
    // At the finish of the tour we need to re-render the component again so we change tabs to do it
    // Since we are using only the columns to apply the tour (joyRide) and avoid breaking the layout
    if (!showOnDesktop) {
      navigate(location.pathname, {
        state: {
          tabToChange: ProgramDetailsTab.programDetails,
          tabFrom: ProgramDetailsTab.invites,
        },
      })
    }
  }

  // Take necessary columns for tour in mobile version
  let actionableTableColumns = tableActionHeaders

  if (!isTabInvitesTourCompleted && !showOnDesktop) {
    actionableTableColumns = tableActionHeaders.slice(-1)
  }

  const rowActionsTourSteps = [
    {
      target: '.featureTour',
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: t(
        'SpeedDialMenu.Tour.StepZero.Content',
        'Click here to see the Edit and Resend Button.'
      ),
      disableBeacon: true,
      disableScrolling: true,
    },
  ]

  if (loadingIds.has(fetchEnrollmentInvitesLoadingId))
    return <LoadingProgress />

  const copyTableToClipboard = () => {
    const headerString =
      actionableTableColumns
        .map((column) => `${column.columnHeaderName}`)
        .join('\t') + '\n'
    const tableString = enrollmentInvites
      .map((e) =>
        [
          e.familyName,
          e.sentToEmail,
          dateToSlashString(e.inviteDate),
          !!e.resentDate ? dateToSlashString(e.resentDate) : '',
          e.offeredSpots,
          e.skippedSpots,
          getLabelForInviteStatus({
            inviteStatus: e.enrollmentInviteStatus,
          }),
        ].join('\t')
      )
      .join('\n')
    navigator.clipboard.writeText(headerString + tableString)

    setSnackbarMessage(
      t(
        'EnrollmentsInviteTable.CopiedToClipboard',
        'Copied table to clipboard!'
      )
    )
    setSnackbarSeverity(SnackbarSeverity.Success)
    setSnackbarState(true)
  }

  return (
    <>
      {/* add class with pointer effect fot the cell */}
      <GlobalStyles
        styles={{
          '.MuiDataGrid-cell-pointer': {
            cursor: 'pointer',
          },
        }}
      />
      <section aria-label="invites">
        {(isSearchingOrFilteringEnrollmentInvites &&
          enrollmentInvites.length === 0) ||
        enrollmentInvites.length > 0 ? (
          <>
            <ConfirmationModal {...confirmationProps} />
            <Section
              aria-label="searchSortAndFilter"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                [theme.breakpoints.down('sm')]: {
                  alignItems: 'center',
                },
              }}
            >
              <Box display="flex">
                <SearchBar handleSearch={handleSearch} />
                <Box
                  sx={{
                    marginLeft: theme.spacing(2),
                    marginTop: theme.spacing(0.25),
                    [theme.breakpoints.down('sm')]: {
                      marginLeft: theme.spacing(0),
                      width: '50%',
                    },
                  }}
                >
                  <DropDown
                    formControlProps={{
                      [theme.breakpoints.down('sm')]: {
                        margin: theme.spacing(0),
                        width: '100%',
                      },
                    }}
                    id="inviteStatusFilter"
                    menuOptions={Object.values(
                      enrollmentInviteStatusFilterOptions
                    ).map((status) => {
                      return {
                        name: getLabelForInviteStatus({ inviteStatus: status }),
                        id: status,
                      }
                    })}
                    defaultValue={getLabelForInviteStatus({
                      inviteStatus: enrollmentInviteFilters.inviteStatus,
                    })}
                    value={getLabelForInviteStatus({
                      inviteStatus: enrollmentInviteFilters.inviteStatus,
                    })}
                    handleSelection={handleInviteStatusFilterSelection}
                    {...{ ...sharedDropDownProps, fullWidth: false }}
                  />
                </Box>
              </Box>
              <TextButton
                id="copyToClipboard"
                variant={TextButtonVariant.CopyToClipboard}
                onClick={copyTableToClipboard}
              />
            </Section>

            {/* Actionable Table */}
            <ActionableTable
              onCompleteTour={() => changeTab()}
              columns={actionableTableColumns}
              isTourCompleted={isTabInvitesTourCompleted ? true : false}
              tourClass="featureTour"
              joyRideTourId="joyride-programDetails-enrollmentInvitesTable-complete"
              rowActionsTourSteps={rowActionsTourSteps}
              rows={enrollmentInvites ?? []}
              noResultsMessage={t(
                'Enrollments.Invite.Table.NoInvite',
                'No Results Found'
              )}
              rowActions={rowActions.length ? rowActions : undefined}
              onCellClick={(params) => {
                const { row, field } = params
                clickableCell(row as EnrollmentInviteRowInfo, field)
              }}
              handlePaginationChange={handlePagination}
              pagination={{
                ...enrollmentInvitesPagination,
                page: enrollmentInvitesPagination.page - 1,
                orderBy: enrollmentInvitesPagination.orderBy.map(
                  (orderBy: string) => {
                    const splitQuery = orderBy.split(' ')
                    return {
                      [splitQuery[0]]: splitQuery[1] as
                        | OrderByDirection
                        | undefined,
                    }
                  }
                ),
              }}
            />
          </>
        ) : (
          <EmptyPage
            message={
              <Typography variant="subtitle1" component="p">
                {t(
                  'Enrollments.Invite.Table.Message.NoEnrollmentsInviteData',
                  'No families have been invited to this program'
                )}
              </Typography>
            }
          />
        )}
      </section>
    </>
  )
}

export default EnrollmentsInviteTable
