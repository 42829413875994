/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 9c90f423 (Thu Aug 29 18:21:31 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  Address,
  AddressFromJSON,
  AddressToJSON,
  DetailMeta,
  DetailMetaFromJSON,
  DetailMetaToJSON,
  ProgramStatus,
  ProgramStatusFromJSON,
  ProgramStatusToJSON,
  ProgramTutors,
  ProgramTutorsFromJSON,
  ProgramTutorsToJSON,
  ProgramType,
  ProgramTypeFromJSON,
  ProgramTypeToJSON,
} from './'

/**
 *
 * @export
 * @interface Program
 */
export interface Program {
  /**
   * The unique identifier assigned by the system when a program is first created.
   * @type {number}
   * @memberof Program
   */
  programsKey: number
  /**
   *
   * @type {ProgramType}
   * @memberof Program
   */
  type: ProgramType
  /**
   *
   * @type {ProgramStatus}
   * @memberof Program
   */
  status: ProgramStatus
  /**
   * **DEPRECATED - Use semesterOneStartDate** The starting date for the program in date format: `yyyy-MM-dd`.
   * @type {Date}
   * @memberof Program
   */
  startDate?: Date
  /**
   * The starting date for the program's first semester in date format: `yyyy-MM-dd`.
   * @type {Date}
   * @memberof Program
   */
  semesterOneStartDate: Date
  /**
   * The starting date for the program's second semester in date format: `yyyy-MM-dd`.
   * @type {Date}
   * @memberof Program
   */
  semesterTwoStartDate: Date
  /**
   * The starting time for the program using ISO standard 24 hour format: `hh:mm`.
   * @type {string}
   * @memberof Program
   */
  startTime?: string
  /**
   * The ending time for the program using ISO standard 24 hour format: `hh:mm`.
   * @type {string}
   * @memberof Program
   */
  endTime?: string
  /**
   * The day of the week the program is available. Where 1 is Monday and 7 is Sunday
   * @type {number}
   * @memberof Program
   */
  dayOfTheWeek?: number
  /**
   * Name of the program director.
   * @type {string}
   * @memberof Program
   */
  director?: string
  /**
   * Email address for the director.
   * @type {string}
   * @memberof Program
   */
  directorEmail?: string
  /**
   * Phone number for the director.
   * @type {string}
   * @memberof Program
   */
  directorPhone?: string
  /**
   * Unique identifier of the actor for the Program Director
   * @type {number}
   * @memberof Program
   */
  directorActorId?: number
  /**
   * Unique identifier of the user for the Program Director
   * @type {number}
   * @memberof Program
   */
  directorUserId?: number
  /**
   * Unique identifier of the actor for the program's assigned tutor.
   * @type {number}
   * @memberof Program
   */
  tutorActorId?: number
  /**
   * Name of the program's assigned tutor.
   * @type {string}
   * @memberof Program
   */
  tutorName?: string
  /**
   * Email address for the program's assigned tutor.
   * @type {string}
   * @memberof Program
   */
  tutorEmail?: string
  /**
   *
   * @type {Array<ProgramTutors>}
   * @memberof Program
   */
  tutors?: Array<ProgramTutors>
  /**
   * The maximum number of students allowed in a program.
   * @type {number}
   * @memberof Program
   */
  capacity?: number
  /**
   * The number of available spots for enrollment in a program.
   * @type {number}
   * @memberof Program
   */
  availableSpots?: number
  /**
   * ISO Currency code e.g. USD
   * @type {string}
   * @memberof Program
   */
  currencyCode?: string
  /**
   * **DEPRECATED** Program membership fees.
   * @type {number}
   * @memberof Program
   */
  membershipFee?: number
  /**
   * Program application fees.
   * @type {number}
   * @memberof Program
   */
  applicationFee?: number
  /**
   * Program enrollment fees.
   * @type {number}
   * @memberof Program
   */
  enrollmentFee?: number
  /**
   * Program supply first students  fees.
   * @type {number}
   * @memberof Program
   */
  supplyFeeFirstStudent?: number
  /**
   * Program supply additional students  fees.
   * @type {number}
   * @memberof Program
   */
  supplyFeeAdditionalStudent?: number
  /**
   * Program tuition fees.
   * @type {number}
   * @memberof Program
   */
  tuition?: number
  /**
   * Program local first students fees.
   * @type {number}
   * @memberof Program
   */
  localFeeFirstStudent?: number
  /**
   * Program facility first students fees.
   * @type {number}
   * @memberof Program
   */
  facilityFeeFirstStudent?: number
  /**
   * Any other miscellaneous program  Additional students fees.
   * @type {number}
   * @memberof Program
   */
  miscFeeFirstStudent?: number
  /**
   * Program local Additional students fees.
   * @type {number}
   * @memberof Program
   */
  localFeeAdditionalStudent?: number
  /**
   * Program facility Additional students fees.
   * @type {number}
   * @memberof Program
   */
  facilityFeeAdditionalStudent?: number
  /**
   * Program invitation fees.
   * @type {number}
   * @memberof Program
   */
  invitationFee?: number
  /**
   * Any other miscellaneous program fees.
   * @type {number}
   * @memberof Program
   */
  miscFeeAdditionalStudent?: number
  /**
   * **DEPRECATED** Any other miscellaneous program fees.
   * @type {number}
   * @memberof Program
   */
  miscFee2?: number
  /**
   * The percentage of fees given to the tutor. Must be a number from 0 to 100.
   * @type {number}
   * @memberof Program
   */
  subLicensedTutorPercentage?: number
  /**
   * A discount to subsequent application fees when more than one student applies to the same program.
   * @type {number}
   * @memberof Program
   */
  multiStudentApplicationDiscount?: number
  /**
   * The fee a director pays CC Home Office per student for the first semester for the first student in their program.
   * @type {number}
   * @memberof Program
   */
  semesterOneLicensingFee?: number
  /**
   * The fee a director pays CC Home Office per student for the first semester for the second+ students in their program.
   * @type {number}
   * @memberof Program
   */
  discountSemesterOneLicensingFee?: number
  /**
   * The fee a director pays CC Home Office per student for the second semester for the first student in their program..
   * @type {number}
   * @memberof Program
   */
  semesterTwoLicensingFee?: number
  /**
   * The fee a director pays CC Home Office per student for the second semester for the second+ students in their program.
   * @type {number}
   * @memberof Program
   */
  discountSemesterTwoLicensingFee?: number
  /**
   * The discount invitation fee per applied to invitation fee
   * @type {number}
   * @memberof Program
   */
  discountInvitationFee?: number
  /**
   * Id of the community in which this program is offered.
   * @type {number}
   * @memberof Program
   */
  communityId: number
  /**
   * Name of the community in which this program is offered.
   * @type {string}
   * @memberof Program
   */
  communityName?: string
  /**
   *
   * @type {Address}
   * @memberof Program
   */
  address: Address
  /**
   *
   * @type {DetailMeta}
   * @memberof Program
   */
  meta?: DetailMeta
  /**
   * Indicates whether or not ACH payments are allowed for the program.
   * @type {boolean}
   * @memberof Program
   */
  achAllowed?: boolean
}

export function ProgramFromJSON(json: any): Program {
  return ProgramFromJSONTyped(json, false)
}

export function ProgramFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Program {
  if (json === undefined || json === null) {
    return json
  }
  return {
    programsKey: json['programsKey'],
    type: ProgramTypeFromJSON(json['type']),
    status: ProgramStatusFromJSON(json['status']),
    startDate: !exists(json, 'startDate')
      ? undefined
      : new Date(json['startDate']),
    semesterOneStartDate: new Date(json['semesterOneStartDate']),
    semesterTwoStartDate: new Date(json['semesterTwoStartDate']),
    startTime: !exists(json, 'startTime') ? undefined : json['startTime'],
    endTime: !exists(json, 'endTime') ? undefined : json['endTime'],
    dayOfTheWeek: !exists(json, 'dayOfTheWeek')
      ? undefined
      : json['dayOfTheWeek'],
    director: !exists(json, 'director') ? undefined : json['director'],
    directorEmail: !exists(json, 'directorEmail')
      ? undefined
      : json['directorEmail'],
    directorPhone: !exists(json, 'directorPhone')
      ? undefined
      : json['directorPhone'],
    directorActorId: !exists(json, 'directorActorId')
      ? undefined
      : json['directorActorId'],
    directorUserId: !exists(json, 'directorUserId')
      ? undefined
      : json['directorUserId'],
    tutorActorId: !exists(json, 'tutorActorId')
      ? undefined
      : json['tutorActorId'],
    tutorName: !exists(json, 'tutorName') ? undefined : json['tutorName'],
    tutorEmail: !exists(json, 'tutorEmail') ? undefined : json['tutorEmail'],
    tutors: !exists(json, 'tutors')
      ? undefined
      : (json['tutors'] as Array<any>).map(ProgramTutorsFromJSON),
    capacity: !exists(json, 'capacity') ? undefined : json['capacity'],
    availableSpots: !exists(json, 'availableSpots')
      ? undefined
      : json['availableSpots'],
    currencyCode: !exists(json, 'currencyCode')
      ? undefined
      : json['currencyCode'],
    membershipFee: !exists(json, 'membershipFee')
      ? undefined
      : json['membershipFee'],
    applicationFee: !exists(json, 'applicationFee')
      ? undefined
      : json['applicationFee'],
    enrollmentFee: !exists(json, 'enrollmentFee')
      ? undefined
      : json['enrollmentFee'],
    supplyFeeFirstStudent: !exists(json, 'supplyFeeFirstStudent')
      ? undefined
      : json['supplyFeeFirstStudent'],
    supplyFeeAdditionalStudent: !exists(json, 'supplyFeeAdditionalStudent')
      ? undefined
      : json['supplyFeeAdditionalStudent'],
    tuition: !exists(json, 'tuition') ? undefined : json['tuition'],
    localFeeFirstStudent: !exists(json, 'localFeeFirstStudent')
      ? undefined
      : json['localFeeFirstStudent'],
    facilityFeeFirstStudent: !exists(json, 'facilityFeeFirstStudent')
      ? undefined
      : json['facilityFeeFirstStudent'],
    miscFeeFirstStudent: !exists(json, 'miscFeeFirstStudent')
      ? undefined
      : json['miscFeeFirstStudent'],
    localFeeAdditionalStudent: !exists(json, 'localFeeAdditionalStudent')
      ? undefined
      : json['localFeeAdditionalStudent'],
    facilityFeeAdditionalStudent: !exists(json, 'facilityFeeAdditionalStudent')
      ? undefined
      : json['facilityFeeAdditionalStudent'],
    invitationFee: !exists(json, 'invitationFee')
      ? undefined
      : json['invitationFee'],
    miscFeeAdditionalStudent: !exists(json, 'miscFeeAdditionalStudent')
      ? undefined
      : json['miscFeeAdditionalStudent'],
    miscFee2: !exists(json, 'miscFee2') ? undefined : json['miscFee2'],
    subLicensedTutorPercentage: !exists(json, 'subLicensedTutorPercentage')
      ? undefined
      : json['subLicensedTutorPercentage'],
    multiStudentApplicationDiscount: !exists(
      json,
      'multiStudentApplicationDiscount'
    )
      ? undefined
      : json['multiStudentApplicationDiscount'],
    semesterOneLicensingFee: !exists(json, 'semesterOneLicensingFee')
      ? undefined
      : json['semesterOneLicensingFee'],
    discountSemesterOneLicensingFee: !exists(
      json,
      'discountSemesterOneLicensingFee'
    )
      ? undefined
      : json['discountSemesterOneLicensingFee'],
    semesterTwoLicensingFee: !exists(json, 'semesterTwoLicensingFee')
      ? undefined
      : json['semesterTwoLicensingFee'],
    discountSemesterTwoLicensingFee: !exists(
      json,
      'discountSemesterTwoLicensingFee'
    )
      ? undefined
      : json['discountSemesterTwoLicensingFee'],
    discountInvitationFee: !exists(json, 'discountInvitationFee')
      ? undefined
      : json['discountInvitationFee'],
    communityId: json['communityId'],
    communityName: !exists(json, 'communityName')
      ? undefined
      : json['communityName'],
    address: AddressFromJSON(json['address']),
    meta: !exists(json, 'meta') ? undefined : DetailMetaFromJSON(json['meta']),
    achAllowed: !exists(json, 'achAllowed') ? undefined : json['achAllowed'],
  }
}

export function ProgramToJSON(value?: Program | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    programsKey: value.programsKey,
    type: ProgramTypeToJSON(value.type),
    status: ProgramStatusToJSON(value.status),
    startDate:
      value.startDate === undefined
        ? undefined
        : value.startDate.toISOString().substr(0, 10),
    semesterOneStartDate: value.semesterOneStartDate
      .toISOString()
      .substr(0, 10),
    semesterTwoStartDate: value.semesterTwoStartDate
      .toISOString()
      .substr(0, 10),
    startTime: value.startTime,
    endTime: value.endTime,
    dayOfTheWeek: value.dayOfTheWeek,
    director: value.director,
    directorEmail: value.directorEmail,
    directorPhone: value.directorPhone,
    directorActorId: value.directorActorId,
    directorUserId: value.directorUserId,
    tutorActorId: value.tutorActorId,
    tutorName: value.tutorName,
    tutorEmail: value.tutorEmail,
    tutors:
      value.tutors === undefined
        ? undefined
        : (value.tutors as Array<any>).map(ProgramTutorsToJSON),
    capacity: value.capacity,
    availableSpots: value.availableSpots,
    currencyCode: value.currencyCode,
    membershipFee: value.membershipFee,
    applicationFee: value.applicationFee,
    enrollmentFee: value.enrollmentFee,
    supplyFeeFirstStudent: value.supplyFeeFirstStudent,
    supplyFeeAdditionalStudent: value.supplyFeeAdditionalStudent,
    tuition: value.tuition,
    localFeeFirstStudent: value.localFeeFirstStudent,
    facilityFeeFirstStudent: value.facilityFeeFirstStudent,
    miscFeeFirstStudent: value.miscFeeFirstStudent,
    localFeeAdditionalStudent: value.localFeeAdditionalStudent,
    facilityFeeAdditionalStudent: value.facilityFeeAdditionalStudent,
    invitationFee: value.invitationFee,
    miscFeeAdditionalStudent: value.miscFeeAdditionalStudent,
    miscFee2: value.miscFee2,
    subLicensedTutorPercentage: value.subLicensedTutorPercentage,
    multiStudentApplicationDiscount: value.multiStudentApplicationDiscount,
    semesterOneLicensingFee: value.semesterOneLicensingFee,
    discountSemesterOneLicensingFee: value.discountSemesterOneLicensingFee,
    semesterTwoLicensingFee: value.semesterTwoLicensingFee,
    discountSemesterTwoLicensingFee: value.discountSemesterTwoLicensingFee,
    discountInvitationFee: value.discountInvitationFee,
    communityId: value.communityId,
    communityName: value.communityName,
    address: AddressToJSON(value.address),
    meta: DetailMetaToJSON(value.meta),
    achAllowed: value.achAllowed,
  }
}
