/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 9c90f423 (Thu Aug 29 18:21:31 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  InlineObject,
  InlineObjectToJSON,
  Region,
  RegionContractingHierarchy,
  RegionContractingHierarchyFromJSON,
  RegionContractingHierarchyRequestBody,
  RegionContractingHierarchyRequestBodyToJSON,
  RegionCreate,
  RegionCreateToJSON,
  RegionFeeSetting,
  RegionFeeSettingFromJSON,
  RegionFromJSON,
  RegionOptions,
  RegionOptionsFromJSON,
  Regions,
  RegionsFromJSON,
  RegionUpdate,
  RegionUpdateToJSON,
  UpdateRegionContentTagsRequestBody,
  UpdateRegionContentTagsRequestBodyToJSON,
} from '../models'
import * as runtime from '../runtime'

export interface CreateRegionRequest extends runtime.BaseRequestParameters {
  body?: RegionCreate
}

export interface CreateRegionContractingHierarchiesRequest
  extends runtime.BaseRequestParameters {
  regionKey: number
  body?: RegionContractingHierarchyRequestBody
}

export interface DeleteRegionContractingHierarchiesRequest
  extends runtime.BaseRequestParameters {
  regionKey: number
  body?: RegionContractingHierarchyRequestBody
}

export interface FetchRegionRequest extends runtime.BaseRequestParameters {
  regionKey: number
}

export interface FetchRegionContractingHierarchiesRequest
  extends runtime.BaseRequestParameters {
  regionKey: number
}

export interface FetchRegionFeesRequest extends runtime.BaseRequestParameters {
  regionKey: number
}

export interface SetRegionFeesRequest extends runtime.BaseRequestParameters {
  regionKey: number
  body?: InlineObject
}

export interface UpdateRegionRequest extends runtime.BaseRequestParameters {
  body?: RegionUpdate
}

export interface UpdateRegionContentTagsRequest
  extends runtime.BaseRequestParameters {
  regionId: number
  body?: UpdateRegionContentTagsRequestBody
}

/**
 *
 */
export class RegionsApi extends runtime.BaseAPI {
  /**
   * This endpoint allows anyone with an active create/Region grant to be able to create a region.
   * Create a new region
   */
  async createRegionRaw(
    requestParameters: CreateRegionRequest
  ): Promise<runtime.ApiResponse<Region>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/regions`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: RegionCreateToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RegionFromJSON(jsonValue)
    )
  }

  /**
   * This endpoint allows anyone with an active create/Region grant to be able to create a region.
   * Create a new region
   */
  async createRegion(requestParameters: CreateRegionRequest): Promise<Region> {
    const response = await this.createRegionRaw(requestParameters)
    return await response.value()
  }

  /**
   *  Create the contracting role hierarchy within a region editable to the requester. A contracting hierarchy is a special permission granted to user\'s with the specified sender role that allows the sender role to contract/license in the receiver role within the specified region.  PERMISSIONS: - The requester must have an active Region/edit permission, else a 403 status code will be returned. - The requester must have permissions to be able to view AND edit the specified region, else a 403 status code will be returned. - A Region/edit[their team\'s] permission allows the requester to create the contracting hierarchies of the regions that they own. - A Region/edit[their team\'s] AND a Region/view[their team\'s] permission allows the requester to create contracting hierarchies of their own regions as well as regions in their downline team. - A Region/edit[any] AND Region/view[their team\'s] permission allows the same access as Region/edit[their team\'s] AND a Region/view[their team\'s] permission. - A Region/edit[their team\'s] AND Region/view[any] permission allows the same access as Region/edit[their team\'s] AND a Region/view[their team\'s] permission. - A Region/edit[any] AND Region/view[any] permission allows the requester to create contracting hierarchies of any region that anyone owns in the system.
   * Create the contracting role hierarchy within a region editable to the requester.
   */
  async createRegionContractingHierarchiesRaw(
    requestParameters: CreateRegionContractingHierarchiesRequest
  ): Promise<runtime.ApiResponse<RegionContractingHierarchy>> {
    if (
      requestParameters.regionKey === null ||
      requestParameters.regionKey === undefined
    ) {
      throw new runtime.RequiredError(
        'regionKey',
        'Required parameter requestParameters.regionKey was null or undefined when calling createRegionContractingHierarchies.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/region/{regionKey}/contractingHierarchies`.replace(
        `{${'regionKey'}}`,
        encodeURIComponent(String(requestParameters.regionKey))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: RegionContractingHierarchyRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RegionContractingHierarchyFromJSON(jsonValue)
    )
  }

  /**
   *  Create the contracting role hierarchy within a region editable to the requester. A contracting hierarchy is a special permission granted to user\'s with the specified sender role that allows the sender role to contract/license in the receiver role within the specified region.  PERMISSIONS: - The requester must have an active Region/edit permission, else a 403 status code will be returned. - The requester must have permissions to be able to view AND edit the specified region, else a 403 status code will be returned. - A Region/edit[their team\'s] permission allows the requester to create the contracting hierarchies of the regions that they own. - A Region/edit[their team\'s] AND a Region/view[their team\'s] permission allows the requester to create contracting hierarchies of their own regions as well as regions in their downline team. - A Region/edit[any] AND Region/view[their team\'s] permission allows the same access as Region/edit[their team\'s] AND a Region/view[their team\'s] permission. - A Region/edit[their team\'s] AND Region/view[any] permission allows the same access as Region/edit[their team\'s] AND a Region/view[their team\'s] permission. - A Region/edit[any] AND Region/view[any] permission allows the requester to create contracting hierarchies of any region that anyone owns in the system.
   * Create the contracting role hierarchy within a region editable to the requester.
   */
  async createRegionContractingHierarchies(
    requestParameters: CreateRegionContractingHierarchiesRequest
  ): Promise<RegionContractingHierarchy> {
    const response = await this.createRegionContractingHierarchiesRaw(
      requestParameters
    )
    return await response.value()
  }

  /**
   *  Delete the contracting role hierarchy within a region editable to the requester. A contracting hierarchy is a special permission granted to user\'s with the specified sender role that allows the sender role to contract/license in the receiver role within the specified region.        PERMISSIONS: - The requester must have an active Region/edit permission, else a 403 status code will be returned. - The requester must have permissions to be able to view AND edit the specified region, else a 403 status code will be returned. - A Region/edit[their team\'s] permission allows the requester to delete the contracting hierarchies of the regions that they own. - A Region/edit[their team\'s] AND a Region/view[their team\'s] permission allows the requester to delete contracting hierarchies of their own regions as well as regions in their downline team. - A Region/edit[any] AND Region/view[their team\'s] permission allows the same access as Region/edit[their team\'s] AND a Region/view[their team\'s] permission. - A Region/edit[their team\'s] AND Region/view[any] permission allows the same access as Region/edit[their team\'s] AND a Region/view[their team\'s] permission. - A Region/edit[any] AND Region/view[any] permission allows the requester to delete contracting hierarchies of any region that anyone owns in the system.
   * Delete the contracting role hierarchy within a region editable to the requester.
   */
  async deleteRegionContractingHierarchiesRaw(
    requestParameters: DeleteRegionContractingHierarchiesRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.regionKey === null ||
      requestParameters.regionKey === undefined
    ) {
      throw new runtime.RequiredError(
        'regionKey',
        'Required parameter requestParameters.regionKey was null or undefined when calling deleteRegionContractingHierarchies.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/region/{regionKey}/contractingHierarchies`.replace(
        `{${'regionKey'}}`,
        encodeURIComponent(String(requestParameters.regionKey))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      body: RegionContractingHierarchyRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   *  Delete the contracting role hierarchy within a region editable to the requester. A contracting hierarchy is a special permission granted to user\'s with the specified sender role that allows the sender role to contract/license in the receiver role within the specified region.        PERMISSIONS: - The requester must have an active Region/edit permission, else a 403 status code will be returned. - The requester must have permissions to be able to view AND edit the specified region, else a 403 status code will be returned. - A Region/edit[their team\'s] permission allows the requester to delete the contracting hierarchies of the regions that they own. - A Region/edit[their team\'s] AND a Region/view[their team\'s] permission allows the requester to delete contracting hierarchies of their own regions as well as regions in their downline team. - A Region/edit[any] AND Region/view[their team\'s] permission allows the same access as Region/edit[their team\'s] AND a Region/view[their team\'s] permission. - A Region/edit[their team\'s] AND Region/view[any] permission allows the same access as Region/edit[their team\'s] AND a Region/view[their team\'s] permission. - A Region/edit[any] AND Region/view[any] permission allows the requester to delete contracting hierarchies of any region that anyone owns in the system.
   * Delete the contracting role hierarchy within a region editable to the requester.
   */
  async deleteRegionContractingHierarchies(
    requestParameters: DeleteRegionContractingHierarchiesRequest
  ): Promise<void> {
    await this.deleteRegionContractingHierarchiesRaw(requestParameters)
  }

  /**
   * Fetch details of a region visible to the requester. The requester must have an active permission to \'view Region\', else a 403 status code will be returned. If their permission is restricted to hierarchy, the returned region must be within the requester\'s upline or downline team.
   * Fetch details of a region visible to the requester.
   */
  async fetchRegionRaw(
    requestParameters: FetchRegionRequest
  ): Promise<runtime.ApiResponse<Region>> {
    if (
      requestParameters.regionKey === null ||
      requestParameters.regionKey === undefined
    ) {
      throw new runtime.RequiredError(
        'regionKey',
        'Required parameter requestParameters.regionKey was null or undefined when calling fetchRegion.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/region/{regionKey}`.replace(
        `{${'regionKey'}}`,
        encodeURIComponent(String(requestParameters.regionKey))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RegionFromJSON(jsonValue)
    )
  }

  /**
   * Fetch details of a region visible to the requester. The requester must have an active permission to \'view Region\', else a 403 status code will be returned. If their permission is restricted to hierarchy, the returned region must be within the requester\'s upline or downline team.
   * Fetch details of a region visible to the requester.
   */
  async fetchRegion(requestParameters: FetchRegionRequest): Promise<Region> {
    const response = await this.fetchRegionRaw(requestParameters)
    return await response.value()
  }

  /**
   *  Fetch the contracting role hierarchy within a region editable to the requester. A contracting hierarchy is a special permission granted to user\'s with the specified sender role that allows the sender role to contract/license in the receiver role within the specified region.  PERMISSIONS: - The requester must have an active Region/view permission, else a 403 status code will be returned. - A Region/view[their team\'s] permission allows the requester to fetch the contracting hierarchies of their own regions as well as regions in their downline and upline team. - A Region/view[any] permission allows the requester to fetch contracting hierarchies of any region that anyone owns in the system.
   * Fetch the contracting role hierarchy within a region visible to the requester.
   */
  async fetchRegionContractingHierarchiesRaw(
    requestParameters: FetchRegionContractingHierarchiesRequest
  ): Promise<runtime.ApiResponse<Array<RegionContractingHierarchy>>> {
    if (
      requestParameters.regionKey === null ||
      requestParameters.regionKey === undefined
    ) {
      throw new runtime.RequiredError(
        'regionKey',
        'Required parameter requestParameters.regionKey was null or undefined when calling fetchRegionContractingHierarchies.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/region/{regionKey}/contractingHierarchies`.replace(
        `{${'regionKey'}}`,
        encodeURIComponent(String(requestParameters.regionKey))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(RegionContractingHierarchyFromJSON)
    )
  }

  /**
   *  Fetch the contracting role hierarchy within a region editable to the requester. A contracting hierarchy is a special permission granted to user\'s with the specified sender role that allows the sender role to contract/license in the receiver role within the specified region.  PERMISSIONS: - The requester must have an active Region/view permission, else a 403 status code will be returned. - A Region/view[their team\'s] permission allows the requester to fetch the contracting hierarchies of their own regions as well as regions in their downline and upline team. - A Region/view[any] permission allows the requester to fetch contracting hierarchies of any region that anyone owns in the system.
   * Fetch the contracting role hierarchy within a region visible to the requester.
   */
  async fetchRegionContractingHierarchies(
    requestParameters: FetchRegionContractingHierarchiesRequest
  ): Promise<Array<RegionContractingHierarchy>> {
    const response = await this.fetchRegionContractingHierarchiesRaw(
      requestParameters
    )
    return await response.value()
  }

  /**
   * Fetch fees associated with a region visible to the requester. The requester must have an active permission to \'view Region\', else a 403 status code will be returned. If their permission is restricted to hierarchy, the returned region must be within the requester\'s upline or downline team.
   * Fetch fees associated with a region visible to the requester.
   */
  async fetchRegionFeesRaw(
    requestParameters: FetchRegionFeesRequest
  ): Promise<runtime.ApiResponse<Array<RegionFeeSetting>>> {
    if (
      requestParameters.regionKey === null ||
      requestParameters.regionKey === undefined
    ) {
      throw new runtime.RequiredError(
        'regionKey',
        'Required parameter requestParameters.regionKey was null or undefined when calling fetchRegionFees.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/region/{regionKey}/fees`.replace(
        `{${'regionKey'}}`,
        encodeURIComponent(String(requestParameters.regionKey))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(RegionFeeSettingFromJSON)
    )
  }

  /**
   * Fetch fees associated with a region visible to the requester. The requester must have an active permission to \'view Region\', else a 403 status code will be returned. If their permission is restricted to hierarchy, the returned region must be within the requester\'s upline or downline team.
   * Fetch fees associated with a region visible to the requester.
   */
  async fetchRegionFees(
    requestParameters: FetchRegionFeesRequest
  ): Promise<Array<RegionFeeSetting>> {
    const response = await this.fetchRegionFeesRaw(requestParameters)
    return await response.value()
  }

  /**
   * The following options are displayed when creating or editing a region: feeTypes, programTypes, region Managers, parent Regions, sender & receiver roles for agreements.
   * Fetch all the options to display in a drop-down when creating or editing a region.
   */
  async fetchRegionOptionsRaw(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<runtime.ApiResponse<RegionOptions>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/regions/options`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RegionOptionsFromJSON(jsonValue)
    )
  }

  /**
   * The following options are displayed when creating or editing a region: feeTypes, programTypes, region Managers, parent Regions, sender & receiver roles for agreements.
   * Fetch all the options to display in a drop-down when creating or editing a region.
   */
  async fetchRegionOptions(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<RegionOptions> {
    const response = await this.fetchRegionOptionsRaw(requestParameters)
    return await response.value()
  }

  /**
   * Fetch details of all regions visible to the requester.     Permissions:    - The requester must have an active Region/view permission, else a 403 status code will be returned.    - An active Region/view[any] permission will return all regions.    - An active Region/view[their team\'s] permission will return all the regions in the user\'s upline and downline for all their valid actors the permission is attached to.
   * Fetch all regions visible to the user.
   */
  async fetchRegionsRaw(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<runtime.ApiResponse<Regions>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/regions`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RegionsFromJSON(jsonValue)
    )
  }

  /**
   * Fetch details of all regions visible to the requester.     Permissions:    - The requester must have an active Region/view permission, else a 403 status code will be returned.    - An active Region/view[any] permission will return all regions.    - An active Region/view[their team\'s] permission will return all the regions in the user\'s upline and downline for all their valid actors the permission is attached to.
   * Fetch all regions visible to the user.
   */
  async fetchRegions(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<Regions> {
    const response = await this.fetchRegionsRaw(requestParameters)
    return await response.value()
  }

  /**
   * Create or update the fees for a region. The fees that are passed will be created if they do not already exist, if they do, then they will be updated. If a fee that already exists is not passed, then it will not be modified.  At least 1 fee update must be passed to get a successful response. ## Permissions: - The requester must have an active Region/edit permission, else a 403 status code will be returned. - An active Region/edit[any] grant will allow the calling user to create or update fees for any region. - An active Region/view[their team\'s] grant will allow the calling user to create or update the fees for any region in their downline.
   * Create or update the fees for a region
   */
  async setRegionFeesRaw(
    requestParameters: SetRegionFeesRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.regionKey === null ||
      requestParameters.regionKey === undefined
    ) {
      throw new runtime.RequiredError(
        'regionKey',
        'Required parameter requestParameters.regionKey was null or undefined when calling setRegionFees.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/region/{regionKey}/fees`.replace(
        `{${'regionKey'}}`,
        encodeURIComponent(String(requestParameters.regionKey))
      ),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: InlineObjectToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Create or update the fees for a region. The fees that are passed will be created if they do not already exist, if they do, then they will be updated. If a fee that already exists is not passed, then it will not be modified.  At least 1 fee update must be passed to get a successful response. ## Permissions: - The requester must have an active Region/edit permission, else a 403 status code will be returned. - An active Region/edit[any] grant will allow the calling user to create or update fees for any region. - An active Region/view[their team\'s] grant will allow the calling user to create or update the fees for any region in their downline.
   * Create or update the fees for a region
   */
  async setRegionFees(requestParameters: SetRegionFeesRequest): Promise<void> {
    await this.setRegionFeesRaw(requestParameters)
  }

  /**
   * This endpoint allows anyone with an active edit/Region grant to be able to update a region.        Only the fields that are passed in will be updated, those that are not included will be kept the same. EXCEPT FOR THE ACTOR KEY, YOU MUST ALWAYS PASS THE ACTOR KEY.    If this field is undefined, the region in the database will be updated with a null value. This is because the front end tool to generate the code to call the backend api does NOT like    properties that have multiple types.
   * Update an existing region
   */
  async updateRegionRaw(
    requestParameters: UpdateRegionRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/regions`,
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: RegionUpdateToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * This endpoint allows anyone with an active edit/Region grant to be able to update a region.        Only the fields that are passed in will be updated, those that are not included will be kept the same. EXCEPT FOR THE ACTOR KEY, YOU MUST ALWAYS PASS THE ACTOR KEY.    If this field is undefined, the region in the database will be updated with a null value. This is because the front end tool to generate the code to call the backend api does NOT like    properties that have multiple types.
   * Update an existing region
   */
  async updateRegion(requestParameters: UpdateRegionRequest): Promise<void> {
    await this.updateRegionRaw(requestParameters)
  }

  /**
   * Updates the active contentTags in a region.     Active content tags received at the endpoint will overwrite existing ones in a region .  At least 1 content tag update must be passed to get a successful response. ## Permissions: - The requester must have an active Region/edit permission, else a 403 status code will be returned. - An active Region/edit[any] grant will allow the calling user to create or update content Tags for any region. - An active Region/view[their team\'s] grant will allow the calling user to create or update the contentTags for any region in their downline.
   * Updates the active content tags in a region.
   */
  async updateRegionContentTagsRaw(
    requestParameters: UpdateRegionContentTagsRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.regionId === null ||
      requestParameters.regionId === undefined
    ) {
      throw new runtime.RequiredError(
        'regionId',
        'Required parameter requestParameters.regionId was null or undefined when calling updateRegionContentTags.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/regions/{regionId}/contentTags`.replace(
        `{${'regionId'}}`,
        encodeURIComponent(String(requestParameters.regionId))
      ),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: UpdateRegionContentTagsRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Updates the active contentTags in a region.     Active content tags received at the endpoint will overwrite existing ones in a region .  At least 1 content tag update must be passed to get a successful response. ## Permissions: - The requester must have an active Region/edit permission, else a 403 status code will be returned. - An active Region/edit[any] grant will allow the calling user to create or update content Tags for any region. - An active Region/view[their team\'s] grant will allow the calling user to create or update the contentTags for any region in their downline.
   * Updates the active content tags in a region.
   */
  async updateRegionContentTags(
    requestParameters: UpdateRegionContentTagsRequest
  ): Promise<void> {
    await this.updateRegionContentTagsRaw(requestParameters)
  }
}
