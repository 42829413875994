/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 9c90f423 (Thu Aug 29 18:21:31 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  AgreementRecord,
  AgreementRecordFromJSON,
  ExpireTeamMemberRequestParams,
  ExpireTeamMemberRequestParamsToJSON,
  InviteTeamMemberOptions,
  InviteTeamMemberOptionsFromJSON,
  InviteTeamMemberRequestBody,
  InviteTeamMemberRequestBodyToJSON,
  ResendInvitationTeamMemberRequestBody,
  ResendInvitationTeamMemberRequestBodyToJSON,
  ResendInviteEmail,
  ResendInviteEmailFromJSON,
  UserTeamAgreementsResponse,
  UserTeamAgreementsResponseFromJSON,
} from '../models'
import * as runtime from '../runtime'

export interface ExpireTeamMemberRequest extends runtime.BaseRequestParameters {
  body?: ExpireTeamMemberRequestParams
}

export interface FetchTeamAgreementsForUserRequest
  extends runtime.BaseRequestParameters {
  userId: string
  page?: number
  pageSize?: number
  orderBy?: Array<string>
  actorKey?: number
  invitationStatus?: string
  search?: string
  downlineOnly?: boolean
}

export interface InviteTeamMemberRequest extends runtime.BaseRequestParameters {
  body?: InviteTeamMemberRequestBody
}

export interface ResendTeamMemberRequest extends runtime.BaseRequestParameters {
  body?: ResendInvitationTeamMemberRequestBody
}

/**
 *
 */
export class TeamsApi extends runtime.BaseAPI {
  /**
   * Expire a team member on the current user\'s team. This translates to setting the validTo date to the current date for that actor. Also, if available, sets the agreementRecord related to the actor to cancelled and sets the cancelled date.
   */
  async expireTeamMemberRaw(
    requestParameters: ExpireTeamMemberRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/teams/expireTeamMember`,
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: ExpireTeamMemberRequestParamsToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Expire a team member on the current user\'s team. This translates to setting the validTo date to the current date for that actor. Also, if available, sets the agreementRecord related to the actor to cancelled and sets the cancelled date.
   */
  async expireTeamMember(
    requestParameters: ExpireTeamMemberRequest
  ): Promise<void> {
    await this.expireTeamMemberRaw(requestParameters)
  }

  /**
   */
  async fetchInviteTeamMemberOptionsRaw(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<runtime.ApiResponse<InviteTeamMemberOptions>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/teams/invite/options`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InviteTeamMemberOptionsFromJSON(jsonValue)
    )
  }

  /**
   */
  async fetchInviteTeamMemberOptions(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<InviteTeamMemberOptions> {
    const response = await this.fetchInviteTeamMemberOptionsRaw(
      requestParameters
    )
    return await response.value()
  }

  /**
   * It will fetch the users agreements record that was sent out to for the previous and current academic Year for    the logged in user if userId = me in case the end point send query string with the actorKey it will also fetch the actor   downline team agreements for the provided actorKey. It will return the user agreements  sent out for the current    and previous academic year, it will return the Name, Email, Role Date Sent, Type of Invite, and the Status of the invitation.\",
   * Fetch user\'s agreements that was sent out for the previous and current academic year for the logged in user    if the end point send query string with the actorKey it will also fetch the actor downline team agreements for the provided actorKey.
   */
  async fetchTeamAgreementsForUserRaw(
    requestParameters: FetchTeamAgreementsForUserRequest
  ): Promise<runtime.ApiResponse<UserTeamAgreementsResponse>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling fetchTeamAgreementsForUser.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters['pageSize'] = requestParameters.pageSize
    }

    if (requestParameters.orderBy) {
      queryParameters['orderBy'] = requestParameters.orderBy.join(
        runtime.COLLECTION_FORMATS['csv']
      )
    }

    if (requestParameters.actorKey !== undefined) {
      queryParameters['actorKey'] = requestParameters.actorKey
    }

    if (requestParameters.invitationStatus !== undefined) {
      queryParameters['invitationStatus'] = requestParameters.invitationStatus
    }

    if (requestParameters.search !== undefined) {
      queryParameters['search'] = requestParameters.search
    }

    if (requestParameters.downlineOnly !== undefined) {
      queryParameters['downlineOnly'] = requestParameters.downlineOnly
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/u/{userId}/team/agreement`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(requestParameters.userId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserTeamAgreementsResponseFromJSON(jsonValue)
    )
  }

  /**
   * It will fetch the users agreements record that was sent out to for the previous and current academic Year for    the logged in user if userId = me in case the end point send query string with the actorKey it will also fetch the actor   downline team agreements for the provided actorKey. It will return the user agreements  sent out for the current    and previous academic year, it will return the Name, Email, Role Date Sent, Type of Invite, and the Status of the invitation.\",
   * Fetch user\'s agreements that was sent out for the previous and current academic year for the logged in user    if the end point send query string with the actorKey it will also fetch the actor downline team agreements for the provided actorKey.
   */
  async fetchTeamAgreementsForUser(
    requestParameters: FetchTeamAgreementsForUserRequest
  ): Promise<UserTeamAgreementsResponse> {
    const response = await this.fetchTeamAgreementsForUserRaw(requestParameters)
    return await response.value()
  }

  /**
   * Send out an email invitation and agreement to a prospective team member.
   */
  async inviteTeamMemberRaw(
    requestParameters: InviteTeamMemberRequest
  ): Promise<runtime.ApiResponse<AgreementRecord>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/teams/invite`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: InviteTeamMemberRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AgreementRecordFromJSON(jsonValue)
    )
  }

  /**
   * Send out an email invitation and agreement to a prospective team member.
   */
  async inviteTeamMember(
    requestParameters: InviteTeamMemberRequest
  ): Promise<AgreementRecord> {
    const response = await this.inviteTeamMemberRaw(requestParameters)
    return await response.value()
  }

  /**
   * This endpoint resend an invite email for a prospective team member that previously signed the contract
   * Resend an email invitation to a prospective team member.
   */
  async resendTeamMemberRaw(
    requestParameters: ResendTeamMemberRequest
  ): Promise<runtime.ApiResponse<ResendInviteEmail>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/invite/resendInviteEmail`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ResendInvitationTeamMemberRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResendInviteEmailFromJSON(jsonValue)
    )
  }

  /**
   * This endpoint resend an invite email for a prospective team member that previously signed the contract
   * Resend an email invitation to a prospective team member.
   */
  async resendTeamMember(
    requestParameters: ResendTeamMemberRequest
  ): Promise<ResendInviteEmail> {
    const response = await this.resendTeamMemberRaw(requestParameters)
    return await response.value()
  }
}
