/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 9c90f423 (Thu Aug 29 18:21:31 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface UserListingRoleAssignment
 */
export interface UserListingRoleAssignment {
  /**
   * Unique identifier for the connection between the user and the role records.
   * @type {number}
   * @memberof UserListingRoleAssignment
   */
  actorKey?: number
  /**
   * Unique identifier for this role.
   * @type {number}
   * @memberof UserListingRoleAssignment
   */
  roleKey: number
  /**
   * Name of this role.
   * @type {string}
   * @memberof UserListingRoleAssignment
   */
  name: string
  /**
   * Name of region which actor belongs.
   * @type {string}
   * @memberof UserListingRoleAssignment
   */
  regionName?: string
  /**
   * The date the user account obtained this role.
   * @type {Date}
   * @memberof UserListingRoleAssignment
   */
  validFrom: Date
  /**
   * The date in which after the user account will no longer have this role.
   * @type {Date}
   * @memberof UserListingRoleAssignment
   */
  validTo: Date
}

export function UserListingRoleAssignmentFromJSON(
  json: any
): UserListingRoleAssignment {
  return UserListingRoleAssignmentFromJSONTyped(json, false)
}

export function UserListingRoleAssignmentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserListingRoleAssignment {
  if (json === undefined || json === null) {
    return json
  }
  return {
    actorKey: !exists(json, 'actorKey') ? undefined : json['actorKey'],
    roleKey: json['roleKey'],
    name: json['name'],
    regionName: !exists(json, 'regionName') ? undefined : json['regionName'],
    validFrom: new Date(json['validFrom']),
    validTo: new Date(json['validTo']),
  }
}

export function UserListingRoleAssignmentToJSON(
  value?: UserListingRoleAssignment | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    actorKey: value.actorKey,
    roleKey: value.roleKey,
    name: value.name,
    regionName: value.regionName,
    validFrom: value.validFrom.toISOString(),
    validTo: value.validTo.toISOString(),
  }
}
