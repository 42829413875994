/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 9c90f423 (Thu Aug 29 18:21:31 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  Community,
  CommunityDetail,
  CommunityDetailFromJSON,
  CommunityOptions,
  CommunityOptionsFromJSON,
  CommunityToJSON,
  CreateCommunityResponse,
  CreateCommunityResponseFromJSON,
  FetchAcademicYearsResponse,
  FetchAcademicYearsResponseFromJSON,
  PublicCommunity,
  PublicCommunityFromJSON,
  PublicInternationalCommunity,
  PublicInternationalCommunityFromJSON,
  SearchPublicCommunitiesReply,
  SearchPublicCommunitiesReplyFromJSON,
  SubmitContactFormParams,
  SubmitContactFormParamsFromJSON,
  SubmitContactFormParamsToJSON,
  UpdateCommunity,
  UpdateCommunityToJSON,
} from '../models'
import * as runtime from '../runtime'

export interface CreateCommunityRequest extends runtime.BaseRequestParameters {
  body?: Community
}

export interface FetchAcademicYearsRequest
  extends runtime.BaseRequestParameters {
  isLC?: boolean
}

export interface FetchCommunityRequest extends runtime.BaseRequestParameters {
  communityKey: number
}

export interface SearchPublicCommunitiesRequest
  extends runtime.BaseRequestParameters {
  postalCode?: string
  city?: string
  state?: string
  milesRadius?: number
  usCitiesKey?: number
  programs?: Array<SearchPublicCommunitiesProgramsEnum>
  singleNearest?: boolean
}

export interface SearchPublicCommunityRequest
  extends runtime.BaseRequestParameters {
  publicCommunityId: string
}

export interface SearchPublicInternationalCommunitiesRequest
  extends runtime.BaseRequestParameters {
  country: string
  programs?: Array<SearchPublicInternationalCommunitiesProgramsEnum>
}

export interface SearchPublicInternationalCommunityRequest
  extends runtime.BaseRequestParameters {
  publicCommunityId: string
}

export interface SubmitContactFormRequest
  extends runtime.BaseRequestParameters {
  body?: SubmitContactFormParams
}

export interface UpdateCommunityRequest extends runtime.BaseRequestParameters {
  body?: UpdateCommunity
}

/**
 *
 */
export class CommunityApi extends runtime.BaseAPI {
  /**
   * Create a new community within a specific region, with an assigned support representative and fee structure. The response will include a `meta.details` array with updated permissions relative to the just-created community, as you would expect when fetching community details later.
   * Create a new community
   */
  async createCommunityRaw(
    requestParameters: CreateCommunityRequest
  ): Promise<runtime.ApiResponse<CreateCommunityResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/community`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CommunityToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CreateCommunityResponseFromJSON(jsonValue)
    )
  }

  /**
   * Create a new community within a specific region, with an assigned support representative and fee structure. The response will include a `meta.details` array with updated permissions relative to the just-created community, as you would expect when fetching community details later.
   * Create a new community
   */
  async createCommunity(
    requestParameters: CreateCommunityRequest
  ): Promise<CreateCommunityResponse> {
    const response = await this.createCommunityRaw(requestParameters)
    return await response.value()
  }

  /**
   * Calling this endpoint, you get the list of the academic years from programs.
   * List of academic years.
   */
  async fetchAcademicYearsRaw(
    requestParameters: FetchAcademicYearsRequest
  ): Promise<runtime.ApiResponse<FetchAcademicYearsResponse>> {
    const queryParameters: any = {}

    if (requestParameters.isLC !== undefined) {
      queryParameters['isLC'] = requestParameters.isLC
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/community/fetchAcademicYears`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FetchAcademicYearsResponseFromJSON(jsonValue)
    )
  }

  /**
   * Calling this endpoint, you get the list of the academic years from programs.
   * List of academic years.
   */
  async fetchAcademicYears(
    requestParameters: FetchAcademicYearsRequest
  ): Promise<FetchAcademicYearsResponse> {
    const response = await this.fetchAcademicYearsRaw(requestParameters)
    return await response.value()
  }

  /**
   * Fetch an existing community
   */
  async fetchCommunityRaw(
    requestParameters: FetchCommunityRequest
  ): Promise<runtime.ApiResponse<CommunityDetail>> {
    if (
      requestParameters.communityKey === null ||
      requestParameters.communityKey === undefined
    ) {
      throw new runtime.RequiredError(
        'communityKey',
        'Required parameter requestParameters.communityKey was null or undefined when calling fetchCommunity.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/community/{communityKey}`.replace(
        `{${'communityKey'}}`,
        encodeURIComponent(String(requestParameters.communityKey))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CommunityDetailFromJSON(jsonValue)
    )
  }

  /**
   * Fetch an existing community
   */
  async fetchCommunity(
    requestParameters: FetchCommunityRequest
  ): Promise<CommunityDetail> {
    const response = await this.fetchCommunityRaw(requestParameters)
    return await response.value()
  }

  /**
   */
  async fetchCommunityOptionsRaw(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<runtime.ApiResponse<CommunityOptions>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/community/options`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CommunityOptionsFromJSON(jsonValue)
    )
  }

  /**
   */
  async fetchCommunityOptions(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<CommunityOptions> {
    const response = await this.fetchCommunityOptionsRaw(requestParameters)
    return await response.value()
  }

  /**
   * Returns some communities within a radius of either a postal code (preferred) or a city and state. If programs are provided, then only communities listing all of the requested programs will be included.
   * Find nearby communities with your desired programs so you can reach out about enrolling.
   */
  async searchPublicCommunitiesRaw(
    requestParameters: SearchPublicCommunitiesRequest
  ): Promise<runtime.ApiResponse<SearchPublicCommunitiesReply>> {
    const queryParameters: any = {}

    if (requestParameters.postalCode !== undefined) {
      queryParameters['postalCode'] = requestParameters.postalCode
    }

    if (requestParameters.city !== undefined) {
      queryParameters['city'] = requestParameters.city
    }

    if (requestParameters.state !== undefined) {
      queryParameters['state'] = requestParameters.state
    }

    if (requestParameters.milesRadius !== undefined) {
      queryParameters['milesRadius'] = requestParameters.milesRadius
    }

    if (requestParameters.usCitiesKey !== undefined) {
      queryParameters['usCitiesKey'] = requestParameters.usCitiesKey
    }

    if (requestParameters.programs) {
      queryParameters['programs'] = requestParameters.programs
    }

    if (requestParameters.singleNearest !== undefined) {
      queryParameters['singleNearest'] = requestParameters.singleNearest
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/communities/search`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SearchPublicCommunitiesReplyFromJSON(jsonValue)
    )
  }

  /**
   * Returns some communities within a radius of either a postal code (preferred) or a city and state. If programs are provided, then only communities listing all of the requested programs will be included.
   * Find nearby communities with your desired programs so you can reach out about enrolling.
   */
  async searchPublicCommunities(
    requestParameters: SearchPublicCommunitiesRequest
  ): Promise<SearchPublicCommunitiesReply> {
    const response = await this.searchPublicCommunitiesRaw(requestParameters)
    return await response.value()
  }

  /**
   * Returns public community details. Note that the `location.distanceMiles`, while included for consistency with the search results, is not meaningful here, and so should be ignored.
   * Review the public details of a community discovered by a location search.
   */
  async searchPublicCommunityRaw(
    requestParameters: SearchPublicCommunityRequest
  ): Promise<runtime.ApiResponse<PublicCommunity>> {
    if (
      requestParameters.publicCommunityId === null ||
      requestParameters.publicCommunityId === undefined
    ) {
      throw new runtime.RequiredError(
        'publicCommunityId',
        'Required parameter requestParameters.publicCommunityId was null or undefined when calling searchPublicCommunity.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/communities/search/{publicCommunityId}`.replace(
        `{${'publicCommunityId'}}`,
        encodeURIComponent(String(requestParameters.publicCommunityId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PublicCommunityFromJSON(jsonValue)
    )
  }

  /**
   * Returns public community details. Note that the `location.distanceMiles`, while included for consistency with the search results, is not meaningful here, and so should be ignored.
   * Review the public details of a community discovered by a location search.
   */
  async searchPublicCommunity(
    requestParameters: SearchPublicCommunityRequest
  ): Promise<PublicCommunity> {
    const response = await this.searchPublicCommunityRaw(requestParameters)
    return await response.value()
  }

  /**
   * Returns communities that belongs the requested country.     The result should include:      - current active/published communities and those in future *      - Include draft communities *     - Include communities without programs .
   * Search for international communities based on a requested country value,    its deliver the the community information, the community contact and the programs related in case it has.
   */
  async searchPublicInternationalCommunitiesRaw(
    requestParameters: SearchPublicInternationalCommunitiesRequest
  ): Promise<runtime.ApiResponse<SearchPublicCommunitiesReply>> {
    if (
      requestParameters.country === null ||
      requestParameters.country === undefined
    ) {
      throw new runtime.RequiredError(
        'country',
        'Required parameter requestParameters.country was null or undefined when calling searchPublicInternationalCommunities.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.country !== undefined) {
      queryParameters['country'] = requestParameters.country
    }

    if (requestParameters.programs) {
      queryParameters['programs'] = requestParameters.programs
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/communities/international/search`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SearchPublicCommunitiesReplyFromJSON(jsonValue)
    )
  }

  /**
   * Returns communities that belongs the requested country.     The result should include:      - current active/published communities and those in future *      - Include draft communities *     - Include communities without programs .
   * Search for international communities based on a requested country value,    its deliver the the community information, the community contact and the programs related in case it has.
   */
  async searchPublicInternationalCommunities(
    requestParameters: SearchPublicInternationalCommunitiesRequest
  ): Promise<SearchPublicCommunitiesReply> {
    const response = await this.searchPublicInternationalCommunitiesRaw(
      requestParameters
    )
    return await response.value()
  }

  /**
   * Returns public community details
   * Search for international community based on a requested community Id encoded,   it delivers the community information, the community contact and the programs related in case it has.
   */
  async searchPublicInternationalCommunityRaw(
    requestParameters: SearchPublicInternationalCommunityRequest
  ): Promise<runtime.ApiResponse<PublicInternationalCommunity>> {
    if (
      requestParameters.publicCommunityId === null ||
      requestParameters.publicCommunityId === undefined
    ) {
      throw new runtime.RequiredError(
        'publicCommunityId',
        'Required parameter requestParameters.publicCommunityId was null or undefined when calling searchPublicInternationalCommunity.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/communities/international/search/{publicCommunityId}`.replace(
        `{${'publicCommunityId'}}`,
        encodeURIComponent(String(requestParameters.publicCommunityId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PublicInternationalCommunityFromJSON(jsonValue)
    )
  }

  /**
   * Returns public community details
   * Search for international community based on a requested community Id encoded,   it delivers the community information, the community contact and the programs related in case it has.
   */
  async searchPublicInternationalCommunity(
    requestParameters: SearchPublicInternationalCommunityRequest
  ): Promise<PublicInternationalCommunity> {
    const response = await this.searchPublicInternationalCommunityRaw(
      requestParameters
    )
    return await response.value()
  }

  /**
   * Accepts info about the person requesting contact, which programs they are interested in at which community, and records their acceptance of the relevant legal notices. Be sure to read the description of the 400 Error for details on field-level error reporting, so you can present backend validation errors to the would-be form submitter.
   * Request someone contact you with more details about a community and its programs.
   */
  async submitContactFormRaw(
    requestParameters: SubmitContactFormRequest
  ): Promise<runtime.ApiResponse<SubmitContactFormParams>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/communities/contact`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: SubmitContactFormParamsToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SubmitContactFormParamsFromJSON(jsonValue)
    )
  }

  /**
   * Accepts info about the person requesting contact, which programs they are interested in at which community, and records their acceptance of the relevant legal notices. Be sure to read the description of the 400 Error for details on field-level error reporting, so you can present backend validation errors to the would-be form submitter.
   * Request someone contact you with more details about a community and its programs.
   */
  async submitContactForm(
    requestParameters: SubmitContactFormRequest
  ): Promise<SubmitContactFormParams> {
    const response = await this.submitContactFormRaw(requestParameters)
    return await response.value()
  }

  /**
   * Create or update a community within a specific region, with an assigned support representative and fee structure.
   * Update an existing community
   */
  async updateCommunityRaw(
    requestParameters: UpdateCommunityRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/community`,
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: UpdateCommunityToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Create or update a community within a specific region, with an assigned support representative and fee structure.
   * Update an existing community
   */
  async updateCommunity(
    requestParameters: UpdateCommunityRequest
  ): Promise<void> {
    await this.updateCommunityRaw(requestParameters)
  }
}

/**
 * @export
 * @enum {string}
 */
export enum SearchPublicCommunitiesProgramsEnum {
  Foundations = 'Foundations',
  Essentials = 'Essentials',
  ChallengeA = 'Challenge A',
  ChallengeB = 'Challenge B',
  ChallengeI = 'Challenge I',
  ChallengeIi = 'Challenge II',
  ChallengeIii = 'Challenge III',
  ChallengeIv = 'Challenge IV',
}
/**
 * @export
 * @enum {string}
 */
export enum SearchPublicInternationalCommunitiesProgramsEnum {
  Foundations = 'Foundations',
  Essentials = 'Essentials',
  ChallengeA = 'Challenge A',
  ChallengeB = 'Challenge B',
  ChallengeI = 'Challenge I',
  ChallengeIi = 'Challenge II',
  ChallengeIii = 'Challenge III',
  ChallengeIv = 'Challenge IV',
}
