/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 9c90f423 (Thu Aug 29 18:21:31 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  AcceptedEnrollmentInviteProgram,
  AcceptedEnrollmentInviteProgramFromJSON,
  AcceptedEnrollmentInviteProgramToJSON,
} from './'

/**
 *
 * @export
 * @interface EnrollmentInviteCompleteRequestBody
 */
export interface EnrollmentInviteCompleteRequestBody {
  /**
   *
   * @type {Array<AcceptedEnrollmentInviteProgram>}
   * @memberof EnrollmentInviteCompleteRequestBody
   */
  acceptedPrograms: Array<AcceptedEnrollmentInviteProgram>
}

export function EnrollmentInviteCompleteRequestBodyFromJSON(
  json: any
): EnrollmentInviteCompleteRequestBody {
  return EnrollmentInviteCompleteRequestBodyFromJSONTyped(json, false)
}

export function EnrollmentInviteCompleteRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnrollmentInviteCompleteRequestBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    acceptedPrograms: (json['acceptedPrograms'] as Array<any>).map(
      AcceptedEnrollmentInviteProgramFromJSON
    ),
  }
}

export function EnrollmentInviteCompleteRequestBodyToJSON(
  value?: EnrollmentInviteCompleteRequestBody | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    acceptedPrograms: (value.acceptedPrograms as Array<any>).map(
      AcceptedEnrollmentInviteProgramToJSON
    ),
  }
}
