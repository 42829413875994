/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 9c90f423 (Thu Aug 29 18:21:31 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  PublicEventLocation,
  PublicEventLocationFromJSON,
  PublicEventLocationToJSON,
  PublicEventType,
  PublicEventTypeFromJSON,
  PublicEventTypeToJSON,
} from './'

/**
 *
 * @export
 * @interface PublicEvent
 */
export interface PublicEvent {
  /**
   * Event unique identifier
   * @type {string}
   * @memberof PublicEvent
   */
  id: string
  /**
   * Name of the event.
   * @type {string}
   * @memberof PublicEvent
   */
  name: string
  /**
   *
   * @type {PublicEventType}
   * @memberof PublicEvent
   */
  eventType: PublicEventType
  /**
   * Description of the event.
   * @type {string}
   * @memberof PublicEvent
   */
  description: string
  /**
   * The starting date for the event in date format: `yyyy-MM-dd`.
   * @type {Date}
   * @memberof PublicEvent
   */
  startDate: Date
  /**
   * The ending date for the event in date format: `yyyy-MM-dd`. To support multi-day events.
   * @type {Date}
   * @memberof PublicEvent
   */
  endDate?: Date
  /**
   * The starting time for the event using ISO standard 24 hour format: `hh:mm`.
   * @type {string}
   * @memberof PublicEvent
   */
  startTime: string
  /**
   * The ending time for the event using ISO standard 24 hour format: `hh:mm`.
   * @type {string}
   * @memberof PublicEvent
   */
  endTime: string
  /**
   * The timezone in which the event date/time is taking place. The timezone is in the IANA time zone format.
   * @type {string}
   * @memberof PublicEvent
   */
  timezone: string
  /**
   * Is this an online event? If true, then provide an event link in onlineEventUrl.
   * @type {boolean}
   * @memberof PublicEvent
   */
  isOnline: boolean
  /**
   * If an online event then provide a URL for the event e.g. a Zoom link.
   * @type {string}
   * @memberof PublicEvent
   */
  onlineEventUrl?: string
  /**
   *
   * @type {PublicEventLocation}
   * @memberof PublicEvent
   */
  location?: PublicEventLocation
  /**
   * A computed value concludes whether or not an event is still accepting registrations. If today's date <= startDate and registration is open and we are not overcapacity, the event is still accepting registrations.
   * @type {boolean}
   * @memberof PublicEvent
   */
  acceptingRegistrations?: boolean
  /**
   * The name of the host for the event, which will be displayed on the marketing site.
   * @type {string}
   * @memberof PublicEvent
   */
  hostName?: string
  /**
   * The phone number of the host, which will be displayed on the marketing site.
   * @type {string}
   * @memberof PublicEvent
   */
  hostPhone?: string
  /**
   * The contact name for the event, which will be displayed on the marketing site.
   * @type {string}
   * @memberof PublicEvent
   */
  contactName?: string
  /**
   * The email for the contact, which will be displayed on the marketing site.
   * @type {string}
   * @memberof PublicEvent
   */
  contactEmail?: string
}

export function PublicEventFromJSON(json: any): PublicEvent {
  return PublicEventFromJSONTyped(json, false)
}

export function PublicEventFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PublicEvent {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    name: json['name'],
    eventType: PublicEventTypeFromJSON(json['eventType']),
    description: json['description'],
    startDate: new Date(json['startDate']),
    endDate: !exists(json, 'endDate') ? undefined : new Date(json['endDate']),
    startTime: json['startTime'],
    endTime: json['endTime'],
    timezone: json['timezone'],
    isOnline: json['isOnline'],
    onlineEventUrl: !exists(json, 'onlineEventUrl')
      ? undefined
      : json['onlineEventUrl'],
    location: !exists(json, 'location')
      ? undefined
      : PublicEventLocationFromJSON(json['location']),
    acceptingRegistrations: !exists(json, 'acceptingRegistrations')
      ? undefined
      : json['acceptingRegistrations'],
    hostName: !exists(json, 'hostName') ? undefined : json['hostName'],
    hostPhone: !exists(json, 'hostPhone') ? undefined : json['hostPhone'],
    contactName: !exists(json, 'contactName') ? undefined : json['contactName'],
    contactEmail: !exists(json, 'contactEmail')
      ? undefined
      : json['contactEmail'],
  }
}

export function PublicEventToJSON(value?: PublicEvent | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    eventType: PublicEventTypeToJSON(value.eventType),
    description: value.description,
    startDate: value.startDate.toISOString().substr(0, 10),
    endDate:
      value.endDate === undefined
        ? undefined
        : value.endDate.toISOString().substr(0, 10),
    startTime: value.startTime,
    endTime: value.endTime,
    timezone: value.timezone,
    isOnline: value.isOnline,
    onlineEventUrl: value.onlineEventUrl,
    location: PublicEventLocationToJSON(value.location),
    acceptingRegistrations: value.acceptingRegistrations,
    hostName: value.hostName,
    hostPhone: value.hostPhone,
    contactName: value.contactName,
    contactEmail: value.contactEmail,
  }
}
