/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 9c90f423 (Thu Aug 29 18:21:31 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface AgreementWebhookRequestBodyAgreementExternalId
 */
export interface AgreementWebhookRequestBodyAgreementExternalId {
  /**
   *
   * @type {string}
   * @memberof AgreementWebhookRequestBodyAgreementExternalId
   */
  id?: string
}

export function AgreementWebhookRequestBodyAgreementExternalIdFromJSON(
  json: any
): AgreementWebhookRequestBodyAgreementExternalId {
  return AgreementWebhookRequestBodyAgreementExternalIdFromJSONTyped(
    json,
    false
  )
}

export function AgreementWebhookRequestBodyAgreementExternalIdFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgreementWebhookRequestBodyAgreementExternalId {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
  }
}

export function AgreementWebhookRequestBodyAgreementExternalIdToJSON(
  value?: AgreementWebhookRequestBodyAgreementExternalId | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
  }
}
