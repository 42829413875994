/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 9c90f423 (Thu Aug 29 18:21:31 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  FetchInviteValidityResponse,
  FetchInviteValidityResponseFromJSON,
  ValidateInvitationFamily,
  ValidateInvitationFamilyResponse,
  ValidateInvitationFamilyResponseFromJSON,
  ValidateInvitationFamilyToJSON,
} from '../models'
import * as runtime from '../runtime'

export interface FetchInviteValidityRequest
  extends runtime.BaseRequestParameters {
  inviteUUID: string
}

export interface ValidateInvitationRequest
  extends runtime.BaseRequestParameters {
  body?: ValidateInvitationFamily
}

/**
 *
 */
export class InvitesApi extends runtime.BaseAPI {
  /**
   * Returns the validity of the invitation and the invitationType, the invitation type can be from a family or a team, the information is get based on the inviteUUId table if the enrollmentInkey key have information it will be family on the other hand in the agreementId have information it will belongs to a team. If valid and invitation is already accepted by a user, also includes the email address of the accepted user which must be used as the login username, also include the InvitationType which can be Team or family
   */
  async fetchInviteValidityRaw(
    requestParameters: FetchInviteValidityRequest
  ): Promise<runtime.ApiResponse<FetchInviteValidityResponse>> {
    if (
      requestParameters.inviteUUID === null ||
      requestParameters.inviteUUID === undefined
    ) {
      throw new runtime.RequiredError(
        'inviteUUID',
        'Required parameter requestParameters.inviteUUID was null or undefined when calling fetchInviteValidity.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/invite/{inviteUUID}/validity`.replace(
        `{${'inviteUUID'}}`,
        encodeURIComponent(String(requestParameters.inviteUUID))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FetchInviteValidityResponseFromJSON(jsonValue)
    )
  }

  /**
   * Returns the validity of the invitation and the invitationType, the invitation type can be from a family or a team, the information is get based on the inviteUUId table if the enrollmentInkey key have information it will be family on the other hand in the agreementId have information it will belongs to a team. If valid and invitation is already accepted by a user, also includes the email address of the accepted user which must be used as the login username, also include the InvitationType which can be Team or family
   */
  async fetchInviteValidity(
    requestParameters: FetchInviteValidityRequest
  ): Promise<FetchInviteValidityResponse> {
    const response = await this.fetchInviteValidityRaw(requestParameters)
    return await response.value()
  }

  /**
   * Receives an object containing the email address and an array of programKeys for that invitation.     When the email address matches a previous invitation in any state for a program, we will return boolean \'hasPreviousInvitations\' as true   indicating that one of the programs has previous invitations, otherwise we return \'hasPreviousInvitations\' as false
   * Validate if a family\'s invitation has previously invitations sent
   */
  async validateInvitationRaw(
    requestParameters: ValidateInvitationRequest
  ): Promise<runtime.ApiResponse<ValidateInvitationFamilyResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/invite/validate`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ValidateInvitationFamilyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ValidateInvitationFamilyResponseFromJSON(jsonValue)
    )
  }

  /**
   * Receives an object containing the email address and an array of programKeys for that invitation.     When the email address matches a previous invitation in any state for a program, we will return boolean \'hasPreviousInvitations\' as true   indicating that one of the programs has previous invitations, otherwise we return \'hasPreviousInvitations\' as false
   * Validate if a family\'s invitation has previously invitations sent
   */
  async validateInvitation(
    requestParameters: ValidateInvitationRequest
  ): Promise<ValidateInvitationFamilyResponse> {
    const response = await this.validateInvitationRaw(requestParameters)
    return await response.value()
  }
}
