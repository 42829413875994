/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 9c90f423 (Thu Aug 29 18:21:31 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  LoginResponseFeatureDefaults,
  LoginResponseFeatureDefaultsFromJSON,
  LoginResponseFeatureDefaultsToJSON,
  Permission,
  PermissionFromJSON,
  PermissionToJSON,
  RegionDiscourseUrl,
  RegionDiscourseUrlFromJSON,
  RegionDiscourseUrlToJSON,
} from './'

/**
 * Successfully authenticated. A secure, HTTP-only `sessionId` cookie will be set.
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
  /**
   *
   * @type {string}
   * @memberof LoginResponse
   */
  authorization: LoginResponseAuthorizationEnum
  /**
   * The set of permissions granted to the user at time of login, without regard to scope. This information can be used to hide or disable completely irrelevant UI.
   * @type {Array<Permission>}
   * @memberof LoginResponse
   */
  permissions?: Array<Permission>
  /**
   * The set of feature defaults granted to the user at time of login, without regard to scope. This information can be used to hide or disable completely irrelevant UI.
   * @type {Array<LoginResponseFeatureDefaults>}
   * @memberof LoginResponse
   */
  featureDefaults?: Array<LoginResponseFeatureDefaults>
  /**
   * The userKey of the successfully logged in user.
   * @type {number}
   * @memberof LoginResponse
   */
  userKey?: number
  /**
   * The current role, via an actor, that the logged in user is acting as. If null, the current role will be considered 'parent'.
   * @type {number}
   * @memberof LoginResponse
   */
  actingAs?: number
  /**
   * Links to discourse forums urls for the regions that the current user belongs to with their current role.
   * @type {Array<RegionDiscourseUrl>}
   * @memberof LoginResponse
   */
  discourseUrls?: Array<RegionDiscourseUrl>
  /**
   * The user’s country of residence
   * @type {string}
   * @memberof LoginResponse
   */
  countryOfResidence?: string
  /**
   * The user’s country of citizenship
   * @type {string}
   * @memberof LoginResponse
   */
  countryOfCitizenship?: string
}

/**
 * @export
 * @enum {string}
 */
export enum LoginResponseAuthorizationEnum {
  Success = 'success',
}

export function LoginResponseFromJSON(json: any): LoginResponse {
  return LoginResponseFromJSONTyped(json, false)
}

export function LoginResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LoginResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    authorization: json['authorization'],
    permissions: !exists(json, 'permissions')
      ? undefined
      : (json['permissions'] as Array<any>).map(PermissionFromJSON),
    featureDefaults: !exists(json, 'featureDefaults')
      ? undefined
      : (json['featureDefaults'] as Array<any>).map(
          LoginResponseFeatureDefaultsFromJSON
        ),
    userKey: !exists(json, 'userKey') ? undefined : json['userKey'],
    actingAs: !exists(json, 'actingAs') ? undefined : json['actingAs'],
    discourseUrls: !exists(json, 'discourseUrls')
      ? undefined
      : (json['discourseUrls'] as Array<any>).map(RegionDiscourseUrlFromJSON),
    countryOfResidence: !exists(json, 'countryOfResidence')
      ? undefined
      : json['countryOfResidence'],
    countryOfCitizenship: !exists(json, 'countryOfCitizenship')
      ? undefined
      : json['countryOfCitizenship'],
  }
}

export function LoginResponseToJSON(value?: LoginResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    authorization: value.authorization,
    permissions:
      value.permissions === undefined
        ? undefined
        : (value.permissions as Array<any>).map(PermissionToJSON),
    featureDefaults:
      value.featureDefaults === undefined
        ? undefined
        : (value.featureDefaults as Array<any>).map(
            LoginResponseFeatureDefaultsToJSON
          ),
    userKey: value.userKey,
    actingAs: value.actingAs,
    discourseUrls:
      value.discourseUrls === undefined
        ? undefined
        : (value.discourseUrls as Array<any>).map(RegionDiscourseUrlToJSON),
    countryOfResidence: value.countryOfResidence,
    countryOfCitizenship: value.countryOfCitizenship,
  }
}
