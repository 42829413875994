/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 9c90f423 (Thu Aug 29 18:21:31 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface RegionUpdate
 */
export interface RegionUpdate {
  /**
   * The unique identifier assigned by the system when a region is first created.
   * @type {number}
   * @memberof RegionUpdate
   */
  regionsKey: number
  /**
   * The name assigned to the region. This is required for region creation.
   * @type {string}
   * @memberof RegionUpdate
   */
  name?: string
  /**
   * The currency code used for the region. ISO-4217 standard codes.
   * @type {string}
   * @memberof RegionUpdate
   */
  currency?: string
  /**
   * A number indicating which set of curriculum a region is using.
   * @type {number}
   * @memberof RegionUpdate
   */
  cycle?: number
  /**
   * The number indicating the month semester one begins. Indexed from 0 to 11. This is required for creation.
   * @type {number}
   * @memberof RegionUpdate
   */
  semesterOneStartMonth?: number
  /**
   * The number indicating the day semester one begins. This is required for creation.
   * @type {number}
   * @memberof RegionUpdate
   */
  semesterOneStartDay?: number
  /**
   * The number indicating the month semester two begins. Indexed from 0 to 11. This is required for creation.
   * @type {number}
   * @memberof RegionUpdate
   */
  semesterTwoStartMonth?: number
  /**
   * The number indicating the day semester two begins. This is required for creation.
   * @type {number}
   * @memberof RegionUpdate
   */
  semesterTwoStartDay?: number
  /**
   * The unique identifier of the region that is a parent of this region. This is required for creation.
   * @type {number}
   * @memberof RegionUpdate
   */
  parentRegionsKey?: number
  /**
   * Boolean indicating if the programs and communities under this region should show up in public searches, such as 'Community Search'.
   * @type {boolean}
   * @memberof RegionUpdate
   */
  isPrivate?: boolean
  /**
   * The actor over the region.
   * @type {number}
   * @memberof RegionUpdate
   */
  actorKey?: number
  /**
   * Boolean indicating if the external automated contracting process within the 'Add a Team Member' process should be bypassed.
   * @type {boolean}
   * @memberof RegionUpdate
   */
  bypassAutomatedAgreements?: boolean
  /**
   * Link to discourse forums url
   * @type {string}
   * @memberof RegionUpdate
   */
  discourseUrl?: string
  /**
   * A year when cycle 1 starts
   * @type {number}
   * @memberof RegionUpdate
   */
  aYCycleStartYear?: number
  /**
   * Boolean indicating whether or not cash payments are allowed for the region
   * @type {boolean}
   * @memberof RegionUpdate
   */
  achAllowed?: boolean
  /**
   * Days before semester one start date when the color of licensing due changes to NORMAL
   * @type {number}
   * @memberof RegionUpdate
   */
  s1LicensingDueOffset?: number
  /**
   * Days after semester one start date when the color of licensing due changes to RED
   * @type {number}
   * @memberof RegionUpdate
   */
  s1LicensingLateOffset?: number
  /**
   * Days before semester two start date when the color of licensing due changes to NORMAL
   * @type {number}
   * @memberof RegionUpdate
   */
  s2LicensingDueOffset?: number
  /**
   * Days after semester two start date when the color of licensing due changes to RED
   * @type {number}
   * @memberof RegionUpdate
   */
  s2LicensingLateOffset?: number
}

export function RegionUpdateFromJSON(json: any): RegionUpdate {
  return RegionUpdateFromJSONTyped(json, false)
}

export function RegionUpdateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RegionUpdate {
  if (json === undefined || json === null) {
    return json
  }
  return {
    regionsKey: json['regionsKey'],
    name: !exists(json, 'name') ? undefined : json['name'],
    currency: !exists(json, 'currency') ? undefined : json['currency'],
    cycle: !exists(json, 'cycle') ? undefined : json['cycle'],
    semesterOneStartMonth: !exists(json, 'semesterOneStartMonth')
      ? undefined
      : json['semesterOneStartMonth'],
    semesterOneStartDay: !exists(json, 'semesterOneStartDay')
      ? undefined
      : json['semesterOneStartDay'],
    semesterTwoStartMonth: !exists(json, 'semesterTwoStartMonth')
      ? undefined
      : json['semesterTwoStartMonth'],
    semesterTwoStartDay: !exists(json, 'semesterTwoStartDay')
      ? undefined
      : json['semesterTwoStartDay'],
    parentRegionsKey: !exists(json, 'parentRegionsKey')
      ? undefined
      : json['parentRegionsKey'],
    isPrivate: !exists(json, 'isPrivate') ? undefined : json['isPrivate'],
    actorKey: !exists(json, 'actorKey') ? undefined : json['actorKey'],
    bypassAutomatedAgreements: !exists(json, 'bypassAutomatedAgreements')
      ? undefined
      : json['bypassAutomatedAgreements'],
    discourseUrl: !exists(json, 'discourseUrl')
      ? undefined
      : json['discourseUrl'],
    aYCycleStartYear: !exists(json, 'AYCycleStartYear')
      ? undefined
      : json['AYCycleStartYear'],
    achAllowed: !exists(json, 'achAllowed') ? undefined : json['achAllowed'],
    s1LicensingDueOffset: !exists(json, 'S1LicensingDueOffset')
      ? undefined
      : json['S1LicensingDueOffset'],
    s1LicensingLateOffset: !exists(json, 'S1LicensingLateOffset')
      ? undefined
      : json['S1LicensingLateOffset'],
    s2LicensingDueOffset: !exists(json, 'S2LicensingDueOffset')
      ? undefined
      : json['S2LicensingDueOffset'],
    s2LicensingLateOffset: !exists(json, 'S2LicensingLateOffset')
      ? undefined
      : json['S2LicensingLateOffset'],
  }
}

export function RegionUpdateToJSON(value?: RegionUpdate | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    regionsKey: value.regionsKey,
    name: value.name,
    currency: value.currency,
    cycle: value.cycle,
    semesterOneStartMonth: value.semesterOneStartMonth,
    semesterOneStartDay: value.semesterOneStartDay,
    semesterTwoStartMonth: value.semesterTwoStartMonth,
    semesterTwoStartDay: value.semesterTwoStartDay,
    parentRegionsKey: value.parentRegionsKey,
    isPrivate: value.isPrivate,
    actorKey: value.actorKey,
    bypassAutomatedAgreements: value.bypassAutomatedAgreements,
    discourseUrl: value.discourseUrl,
    AYCycleStartYear: value.aYCycleStartYear,
    achAllowed: value.achAllowed,
    S1LicensingDueOffset: value.s1LicensingDueOffset,
    S1LicensingLateOffset: value.s1LicensingLateOffset,
    S2LicensingDueOffset: value.s2LicensingDueOffset,
    S2LicensingLateOffset: value.s2LicensingLateOffset,
  }
}
