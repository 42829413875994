import Typography from '@mui/material/Typography'
import React, { createContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { fetchBilling } from '../../../api/payments'
import {
  EnrollmentBill,
  extractedErrorObject,
  LicensingBill,
  ParentInvoiceInfo,
  PaymentConfiguration,
  PaymentConfigurationCodeEnum,
} from '../../../api/swagger'
import { CanAccess } from '../../Elements/Access'
import EmptyPage from '../../Elements/EmptyPage'
import { Payment, PaymentMade } from '../../Interfaces/Payment'
import { AnnualEnrollmentPaymentCard } from './AnnualEnrollmentPaymentCard'
import { BillingHistorySummaryTableVariant } from './BillingHistorySummaryTable'
import { LicensingPaymentsCard } from './LicensingPaymentsCard'
import LoadingProgress from '../../Elements/LoadingProgress'
import { LoadingContext } from '../../Context/LoadingContext'
import useLoadingContext from '../../../hooks/useLoadingContext'
import { useLoadingIds } from '../../../hooks/useLoadingIds'
import { useMountEffect } from '../../../hooks/useMountEffect'
import {
  SnackbarSeverity,
  useSnackbarContext,
} from '../../Context/SnackbarContext'
import { ParentInvoiceCard } from './ParentInvoiceCard'
import { Box } from '@mui/material'

/** Context */
export const LoadingBillingContext = createContext(true)

export const BillingTab: React.FC = () => {
  const { t } = useTranslation()
  const { addLoadingIds, loadingIds } = React.useContext(LoadingContext)
  const availableLoadingIds = useLoadingIds()
  const { setSnackbarMessage, setSnackbarSeverity, setSnackbarState } =
    useSnackbarContext()

  const defaultFetchBillingErrorMessage = t(
    'AnnualEnrollmentPaymentCard.FetchBilling.Error',
    'Error occurred fetching billing history.'
  )

  const [licensingPaymentHistory, setLicensingPaymentHistory] = useState<
    Payment[]
  >([])
  const [licensingBills, setLicensingBills] = useState<LicensingBill[]>([])

  const [enrollmentPaymentHistory, setEnrollmentPaymentHistory] = useState<
    Payment[]
  >([])
  const [enrollmentBill, setEnrollmentBill] = useState<
    EnrollmentBill | undefined
  >()

  const [parentInvoices, setParentInvoices] = useState<ParentInvoiceInfo[]>([])

  const [configurations, setConfigurations] = useState<PaymentConfiguration[]>(
    []
  )
  const [triggerRefetch, setTriggerRefetch] = useState(false)
  const [isAchAllowed, setIsAchAllowed] = useState(true)

  const fetchBillingHistory = async () => {
    try {
      const {
        enrollment,
        licensing,
        configurations: fetchedConfigs,
        achAllowed,
        parentInvoices,
      } = await fetchBilling({})
      // if (isMounted()) {
      setConfigurations(fetchedConfigs)
      setIsAchAllowed(achAllowed)
      setParentInvoices(parentInvoices ?? [])
      // Setup all billing history data based on the object's information
      if (!!enrollment) {
        if (!!enrollment.history && enrollment.history.length > 0) {
          // Temporary array to store all programKey for history/enrollment payments
          let programKeysForHistory: number[] = []
          setEnrollmentPaymentHistory(
            enrollment.history.map((it) => {
              const paymentHistory: Payment = {
                ...it,
                paymentsMade: it.enrollmentPayments.map((enrollmentPayment) => {
                  const paymentMade: PaymentMade = {
                    ...enrollmentPayment,
                    paymentKey: enrollmentPayment.enrollmentPaymentKey,
                  }
                  return paymentMade
                }),
              }
              // Reduce each enrollment payment to just the program key
              programKeysForHistory = it.enrollmentPayments.reduce(
                (acc, curr) => [...acc, curr.programKey],
                programKeysForHistory
              )
              return paymentHistory
            })
          )
        }
        setEnrollmentBill(enrollment.owed)
      }
      if (!!licensing) {
        if (!!licensing.history && licensing.history.length > 0) {
          setLicensingPaymentHistory(
            licensing.history.map((it) => {
              const paymentHistory: Payment = {
                ...it,
                paymentsMade: [
                  {
                    ...it.licensingPayment,
                    paymentKey: it.licensingPayment.licensingPaymentKey,
                    studentKey: -1,
                  },
                ], // No student key applicable to licensing payment, but shared with enrollments
              }
              return paymentHistory
            })
          )
        }
        if (!!licensing.programs && licensing.programs.length > 0) {
          setLicensingBills(licensing.programs)
        }
      }
      // }
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message:
          (e as unknown as Error).message ?? defaultFetchBillingErrorMessage,
      }
      // At a minimum log any errors
      setSnackbarMessage(errorObject.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarState(true)
    }
  }

  /** Hooks */
  useLoadingContext({
    asyncFunction: fetchBillingHistory,
    loadingId: availableLoadingIds.BillingTab.fetchBilling,
  })

  /** Load billing history for current user */
  useEffect(() => {
    addLoadingIds([availableLoadingIds.BillingTab.fetchBilling])
  }, [
    addLoadingIds,
    availableLoadingIds.BillingTab.fetchBilling,
    triggerRefetch,
  ])

  useMountEffect(() => {
    addLoadingIds([availableLoadingIds.BillingTab.fetchBilling])
  })

  const achDiscount =
    configurations.find(
      (configuration) =>
        configuration.code === PaymentConfigurationCodeEnum.AchDiscount
    )?.amount ?? 0

  // Program map would be built if there were programs present within enrollments or licensing.
  // If the program Map is not built and we have attempted a load, we likely have NO data.
  const billingDataFetchedAndEmpty =
    !!enrollmentPaymentHistory &&
    enrollmentPaymentHistory.length === 0 &&
    !!licensingBills &&
    licensingBills.length === 0 &&
    !!licensingPaymentHistory &&
    licensingPaymentHistory.length === 0 &&
    !enrollmentBill

  if (loadingIds.has(availableLoadingIds.BillingTab.fetchBilling)) {
    return <LoadingProgress />
  }

  //Director view will have more parts to the billing tab
  return (
    <CanAccess I="billing" on="Feature">
      {!billingDataFetchedAndEmpty ? (
        <>
          {(!!enrollmentBill ||
            (!!enrollmentPaymentHistory &&
              enrollmentPaymentHistory.length > 0)) && (
            <AnnualEnrollmentPaymentCard
              enrollmentPaymentHistory={enrollmentPaymentHistory}
              enrollmentBill={enrollmentBill}
              refetch={() => setTriggerRefetch((prev) => !prev)}
              achDiscount={achDiscount}
            />
          )}
          {((!!licensingBills && licensingBills.length > 0) ||
            (!!licensingPaymentHistory &&
              licensingPaymentHistory.length > 0)) && (
            <LicensingPaymentsCard
              licensingBills={licensingBills}
              licensingPaymentHistory={licensingPaymentHistory}
              refetch={() => setTriggerRefetch((prev) => !prev)}
              achDiscount={achDiscount}
              variantForBillingHistorySummaryTable={
                BillingHistorySummaryTableVariant.LicensingPayments
              }
              achAllowed={isAchAllowed}
            />
          )}
          <Box mt={11}>
            <ParentInvoiceCard parentInvoices={parentInvoices} />
          </Box>
        </>
      ) : (
        <EmptyPage
          message={
            <Typography variant="subtitle1" component="p">
              {t(
                'BillingTab.Message.NoBillingData',
                'No billing information available.'
              )}
            </Typography>
          }
        />
      )}
    </CanAccess>
  )
}

export default BillingTab
