/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 9c90f423 (Thu Aug 29 18:21:31 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import { ProgramInvite, ProgramInviteFromJSON, ProgramInviteToJSON } from './'

/**
 *
 * @export
 * @interface EnrollmentInviteCreate
 */
export interface EnrollmentInviteCreate {
  /**
   * Email that the invite was sent to. Will be validated. If failed, will return 400 with an error code: InvalidParam target: parentEmail. cf. submitContactForm, signup.
   * @type {string}
   * @memberof EnrollmentInviteCreate
   */
  parentEmail: string
  /**
   * First name of the parent the invitation is being sent to. Will be trimmed and validated for length.
   * @type {string}
   * @memberof EnrollmentInviteCreate
   */
  parentFirstName: string
  /**
   * The last name provided by the user. Will be trimmed and validated for length.
   * @type {string}
   * @memberof EnrollmentInviteCreate
   */
  parentLastName: string
  /**
   * Unique identifier for the parent user to bind to the invitation. Leave blank if this is a new or unknown family. If provided, must be a user that the inviting user is allowed to see, else 400 code: InvalidParam target: parentUserKey will be returned. Cf. fetchInviteFamilyOptions.
   * @type {number}
   * @memberof EnrollmentInviteCreate
   */
  parentUserKey?: number
  /**
   * Unique identifier for the community that contains the programs the parent is being invited to enroll their student in. If provided, must be a community that the inviting user is allowed to see, which is implicit in the program being visible. Cf. fetchInviteFamilyOptions. (This is actually entirely superfluous with the program options.)
   * @type {number}
   * @memberof EnrollmentInviteCreate
   */
  communityKey: number
  /**
   * The programs this enrollment invitation pertains to, as well as the number of student spots per program. Each program must belong to the same community. Cf. fetchInviteFamilyOptions.
   * @type {Array<ProgramInvite>}
   * @memberof EnrollmentInviteCreate
   */
  programs: Array<ProgramInvite>
}

export function EnrollmentInviteCreateFromJSON(
  json: any
): EnrollmentInviteCreate {
  return EnrollmentInviteCreateFromJSONTyped(json, false)
}

export function EnrollmentInviteCreateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnrollmentInviteCreate {
  if (json === undefined || json === null) {
    return json
  }
  return {
    parentEmail: json['parentEmail'],
    parentFirstName: json['parentFirstName'],
    parentLastName: json['parentLastName'],
    parentUserKey: !exists(json, 'parentUserKey')
      ? undefined
      : json['parentUserKey'],
    communityKey: json['communityKey'],
    programs: (json['programs'] as Array<any>).map(ProgramInviteFromJSON),
  }
}

export function EnrollmentInviteCreateToJSON(
  value?: EnrollmentInviteCreate | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    parentEmail: value.parentEmail,
    parentFirstName: value.parentFirstName,
    parentLastName: value.parentLastName,
    parentUserKey: value.parentUserKey,
    communityKey: value.communityKey,
    programs: (value.programs as Array<any>).map(ProgramInviteToJSON),
  }
}
