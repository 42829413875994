/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 9c90f423 (Thu Aug 29 18:21:31 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  FetchDiscourseConnectRedirectUrl,
  FetchDiscourseConnectRedirectUrlBody,
  FetchDiscourseConnectRedirectUrlBodyToJSON,
  FetchDiscourseConnectRedirectUrlFromJSON,
} from '../models'
import * as runtime from '../runtime'

export interface FetchDiscourseConnectRedirectUrlRequest
  extends runtime.BaseRequestParameters {
  userId: string
  body?: FetchDiscourseConnectRedirectUrlBody
}

/**
 *
 */
export class UserApi extends runtime.BaseAPI {
  /**
   * Gathers necessary [Discourse SSO](https://meta.discourse.org/t/discourseconnect-official-single-sign-on-for-discourse-sso/13045) link to redirect to
   */
  async fetchDiscourseConnectRedirectUrlRaw(
    requestParameters: FetchDiscourseConnectRedirectUrlRequest
  ): Promise<runtime.ApiResponse<FetchDiscourseConnectRedirectUrl>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling fetchDiscourseConnectRedirectUrl.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/u/{userId}/discourse_sso`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(requestParameters.userId))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: FetchDiscourseConnectRedirectUrlBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FetchDiscourseConnectRedirectUrlFromJSON(jsonValue)
    )
  }

  /**
   * Gathers necessary [Discourse SSO](https://meta.discourse.org/t/discourseconnect-official-single-sign-on-for-discourse-sso/13045) link to redirect to
   */
  async fetchDiscourseConnectRedirectUrl(
    requestParameters: FetchDiscourseConnectRedirectUrlRequest
  ): Promise<FetchDiscourseConnectRedirectUrl> {
    const response = await this.fetchDiscourseConnectRedirectUrlRaw(
      requestParameters
    )
    return await response.value()
  }
}
